import React, { useEffect } from "react";
import "./BusinessSolutionCard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const BusinessSolutionCard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const cards = [
    {
      iconSrc: require("../assets/images/amazonIcon.png"),
      title: "Amazon FBA",
      description:
        "Our tailored Amazon FBA solutions enhance inventory management, boost product visibility, and drive significant sales.",
      highlightDescription: "Explore This Feature",
      VectorIcon: require("../assets/images/vector.png"),
      linkTo: "/amazon-fba/",
    },
    {
      iconSrc: require("../assets/images/shopifyIcon.png"),
      title: "Shopify Automation",
      description:
        "Automate your Shopify store effortlessly, improving efficiency and customer satisfaction to drive higher conversions.",
      highlightDescription: "Explore This Feature",
      linkTo: "/shopify-automation/",
    },
    {
      iconSrc: require("../assets/images/wallmartIcon.png"),
      title: "Walmart Automation",
      description:
        "Dominate the Walmart marketplace with our comprehensive automation strategies. We improve your product rankings, drive higher visibility, and significantly boost sales.",
      highlightDescription: "Explore This Feature",
      secondVector: require("../assets/images/vectorSecond.png"),
      linkTo: "/walmart-automation/",
    },
    {
      iconSrc: require("../assets/images/Group 50.png"),
      title: "TikTok Automation",
      description:
        "Leverage TikTok's dynamic platform with our automation expertise. We turn viral trends into profitable sales channels, expanding your reach and driving revenue growth.",
      highlightDescription: "Explore This Feature",
      // secondVector: require("../assets/images/vectorSecond.png"),
      linkTo: "/tiktok-automation/",
    },
  ];

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="business-solution-cards global-background">
      <div
        className="text-center py-5 py-lg-0"
        data-aos="zoom-in"
        data-aos-duration="1000"
      >
        <h1 className="font-face-Bold text-theme-white">
          Transform Your E-commerce
          <span className="d-md-block">Success with Expert Solutions</span>
        </h1>
        <p
          className="font-face-Poppins-Regular text-theme-light-white m-0 px-2 px-lg-0 px-md-0"
          data-aos="zoom-in"
          data-aos-duration="1100"
        >
          Unleash your business's potential with our specialized services. We
          propel
          <span className="d-md-block">
            your products to the forefront, ensuring sustained growth and a
            thriving E-commerce store.
          </span>
        </p>
      </div>
      <div className="container py-md-5 mx-auto">
        <div className="cards-view row px-xl-5 mx-auto">
          <Slider {...settings}>
            {cards?.map((x, index) => {
              let className;
              if (index === 0) {
                className = "cards-box position-relative";
              } else if (index === 1) {
                className = "cards-box2";
              } else if (index === 2) {
                className = "cards-box3 position-relative";
              } else if (index === 3) {
                className = "cards-box4 position-relative";
              }
              return (
                <div
                  key={index}
                  className={`col-lg-4 p-0  ${
                    index !== cards.length - 1 ? "mb-5 mb-lg-0" : ""
                  }  text-center ${className}`}
                  data-aos="flip-left"
                  data-aos-duration="800"
                >
                  <div className="inner">
                    {x.VectorIcon ? (
                      <img
                        className="vector-image"
                        src={x.VectorIcon}
                        alt="VectorIcon"
                      />
                    ) : null}
                    {x.secondVector ? (
                      <img
                        className="second-vector-image"
                        src={x.secondVector}
                        alt="VectorIcon"
                      />
                    ) : null}

                    <img src={x.iconSrc} alt="walmartIcon" />

                    <div>
                      <h4 className="font-face-Bold pb-4">{x.title}</h4>
                      <p className="font-face-Poppins-Regular   px-4 mx-auto">
                        {x.description}
                      </p>
                      <p
                        className="font-face-Manrope-Extra-Bold"
                        onClick={() => handleNavigate(x.linkTo)}
                      >
                        {x.highlightDescription}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default BusinessSolutionCard;
