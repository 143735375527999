import React, { useEffect } from "react";
import bannerImage from "../assets/images/walmartBanner.png";
import rightImage from "../assets/images/wallmartRightImage.png";
import AboutBanner from "../components/AboutBanner";
import CustomServices from "../components/CustomServices";
import FbExpertsPortfolio from "../components/FbExpertsPortfolio";
import ProductExcellence from "../components/ProductExcellence";
import ResultDrivenSection from "../components/ResultDrivenSection";
import DynamicSeo from "../utils/DynamicSeo";

const WalmartAutomationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "We Always Give The Best";
  const bannerSecondaryHeading = "Walmart Automation";
  const bannerTertiaryHeading = "Service To You";

  const customServiceData = [
    {
      headingOne: "Sky-Rocket Your Walmart Venture",
      headingSecond: "with Our Premium Walmart",
      headingThird: "Automation Services",
      descriptionOne:
        "Are you ready to revolutionize your Walmart Dropshipping venture? FBAXperts offers tailored services that helps you in attaining maximum profitability & boost exponential growth. Also, we assist in improving your sales, & we’ll manage everything – from inventory management to store optimization.",
      descriptionTwo:
        "Our primary aim is to liberate you from mundane tasks, enabling you to concentrate on strategic growth and revenue generation. With a diverse range of premium products and our dedicated services, we empower you to focus on boosting sales & growth, thus achieving impressive results.",
      serviceImage: require("../assets/images/walmartService.png"),
    },
    {
      headingOne: "Boost Your Walmart Success with",
      headingSecond: "FBAXperts Dropshipping Power Duo",
      descriptionOne:
        "Looking to dominate on one of the world's biggest online platforms? Reach to our elite e-commerce company and take your business on higher levels with our Walmart Automation Services. From product sourcing to shipping, we handle it all, freeing you up for business growth and top-tier customer service.",
      descriptionTwo:
        "Our premium products seamlessly integrate into your Walmart store, promising a surge in sales and profits. Plus, our curated premium products, designed to blend perfectly with your Walmart store, ensure not just more sales, but a substantial boost in profitability.",
      serviceImage: require("../assets/images/walmartService2.png"),
    },
  ];

  const productExcellenceData = [
    {
      productTitleOne: "We Provide the Best Services",
      productTitleTwo: "for Your Product",
      productDescription:
        "At FBAXperts, we offer top-tier solutions designed to streamline your Amazon business. Our expert team ensures every aspect operates flawlessly & seamlessly.",
      productIconOne: require("../assets/images/ProductExcellenceIcon1.png"),
      featureProducts: [
        {
          icon: require("../assets/images/ProductExcellenceIcon1.png"),
          featureHeading: "Expertise in Inventory Management",
          description:
            "At FBAXperts, we offer top-tier solutions designed to streamline your Amazon business. Our expert team ensures every aspect operates flawlessly & seamlessly.",
        },
        {
          icon: require("../assets/images/ProductExcellenceIcon2.png"),
          featureHeading: "Superior Order Fulfillment Solutions",
          description:
            "We guarantee fast, reliable delivery, ensuring your customers receive their orders promptly and in perfect condition.",
        },
      ],

      productImage: require("../assets/images/ProductExcellenceImage4.png"),
      productImageMobile: require("../assets/images/ProductExcellenceImage4Mobile.png"),
    },
  ];

  const portfolioImags = [
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 287.png"),
    },
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 288.png"),
    },
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 289.png"),
    },
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 290.png"),
    },
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 291.png"),
    },
    {
      revenueImage: require("../assets/images/Walmart/Rectangle 292.png"),
    },
  ];

  const FAQDATA = [
    {
      heading:
        "What opportunities does Amazon FBA Automation services provide to maximize my profit?",

      description:
        "Amazon FBA automation is about order fulfillment, inventory management and effortless shipping which means it is all about saving the time and efforts. Our experts will help you in managing inventories and in determining the appropriate stock levels that needs to be replenished and adjust prices to suit the current sales performance.",
    },
    {
      heading:
        "What opportunities does Amazon FBA Automation services provide to maximize my profit?",

      description:
        "FBAXperts differentiates itself through individualized approaches stemming from a knowledge of how Amazon works and what is trending in the modern world of e-commerce. Our approach is centered on product exposure and our aim is to empower businesses of all sizes in achieving massive sales & revenue.",
    },
    {
      heading:
        "Which kind of businesses should use Amazon FBA automation services?",

      description:
        "Utilizing Amazon FBA automation services is advantageous for almost every business. Whether you are a new seller who wants to escalate in the rivalrous domain shortly or you are an already established brand that is looking forward to expand further on the Amazon market, then you should consider these services. Our premium services are designed to help you grow as fast as you want and as big as you wish on the Amazon marketplace.",
    },
    {
      heading:
        "What measures do you take to ensure that you follow Amazon policies and regulations? ",

      description:
        "FBAXperts ensure that they operate within the amazon policies and implement checks and balances mostly frequently to allow compliance with changes implemented by Amazon. Our approaches and methods do not violate any rules and regulations provided by Amazon to ensure that the account does not get banned.",
    },
    {
      heading:
        "Is it possible that I can track the performance of my products and sales through your services?",

      description: `Yes, FBAXperts does offer several detailed analysis and reports through which you can monitor several aspects, such as product specific performance & sales. These tools provide information about things like sales, customers, and inventory which are very important in any business. Using these findings, Amazon sellers can objectively evaluate the effectiveness of their strategies, define improvement opportunities, and enhance their activity for superior performance.`,
    },
  ];
  return (
    <div>
      <DynamicSeo
        title="Walmart Automation Services | FBAXperts"
        description="Looking for a reputable e-commerce business to help you boost your Walmart store’s online presence? Let’s get in touch with us at FBAXperts & get impeccable services."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        bannerTertiaryHeading={bannerTertiaryHeading}
        rightImage={rightImage}
      />
      <CustomServices customServiceData={customServiceData} />
      <FbExpertsPortfolio portfolioImags={portfolioImags} />
      <ProductExcellence productExcellenceData={productExcellenceData} />
      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default WalmartAutomationPage;
