import React, { useRef, useState } from "react";
import "./FbExpertSubscribeForm.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

const FbExpertSubscribeForm = () => {
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [notes, setNotes] = useState("");

  const recaptchaRef = useRef();
  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const data = [
    {
      icon: require("../assets/images/subscribeFormIcon1.png"),
      title: "Join Our Mailing List",
      description:
        "Subscribe for the latest updates, tips, and exclusive offers from FBAXperts. Stay ahead with our expert insights and news.",
    },
    {
      icon: require("../assets/images/subscribeFormIcon2.png"),
      title: "Get Exclusive Insights",
      description:
        "Unlock premium content and special deals. Don't miss out on valuable information for your business.",
    },
    {
      icon: require("../assets/images/subscribeFormIcon3.png"),
      title: "Be the First to Know",
      description:
        "Sign up for early access to new features, services, and promotions. Keep your competitive edge with timely updates.",
    },
  ];

  const handleKeyDown = (e) => {
    // Allow only numeric input
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== " " &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("name");

    const phoneInput = document.getElementById("phone");
    const messageInput = document.getElementById("message");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === "" ||
      messageInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    const response = await fetch(
      "https://backend.fbaxperts.com/api/email/V1/contactInfoMail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: phoneNumber,
          userMessage: notes,
        }),
      }
    );
    const responseData = await response.json();

    // console.log(responseData.msg);
    document.getElementById("myForm").reset();
    // recaptchaRef.current.reset();
    // setVerified(false);
    navigate("/thankyou/");
  };
  return (
    <div className="subscribe-form" id="contactSection">
      <div className="box container py-5">
        <form onSubmit={handleSubmit} id="myForm">
          <div className="row">
            <div className="col-md-6 mb-5 mb-lg-0 mb-md-0 px-lg-5 d-flex flex-column justify-content-center">
              <h2 className="subscribe-heading font-face-Bold text-center">
                Stay Updated with FBAXperts
              </h2>

              <p className="font-face-Poppins-Medium subscribe-description text-center">
                Trusted by Thousands of Customers
              </p>

              <div className="d-xl-flex align-items-center mt-4 justify-content-between">
                <div>
                  <input
                    className="width-90 font-face-Poppins-Medium"
                    id="name"
                    placeholder="Full Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="mt-3 mt-xl-0 mt-lg-3 mt-md-3 number-view">
                  <input
                    className="width-90 font-face-Poppins-Medium "
                    id="phone"
                    placeholder="Phone Number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>

              <div className="mt-3">
                <input
                  className="w-100 font-face-Poppins-Medium "
                  id="email"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-3">
                <textarea
                  id="message"
                  rows={4}
                  className="w-100 font-face-Poppins-Medium "
                  placeholder="Message"
                  onChange={(e) => setNotes(e.target.value)}
                ></textarea>
              </div>

              {/* <div className="d-flex align-items-center pt-2">
                <input type="checkbox" />
                <div className="font-face-Manrope-Medium subscribe-description ms-2">
                  I agree with term & condition
                </div>
              </div> */}

              <div className="mt-4" />
              {/* 
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdbUecpAAAAACGzWB5RPhf5ewsNEt69crId5dJC"
                onChange={onChange}
                className="mb-4"
              /> */}

              <button
                // disabled={!verified}
                // className={`global-light-btn-submit font-face-Manrope-Extra-Bold w-100 text-center ${
                //   verified ? "enabled" : ""
                // }`}
                className="global-light-btn-submit font-face-Manrope-Extra-Bold w-100 text-center"
              >
                Submit Request
              </button>
            </div>

            <div className="col-md-6 d-flex flex-column align-items-end justify-content-center px-md-5">
              {data?.map((x, index) => {
                const isLastItem = index === data.length - 1;
                return (
                  <div key={index}>
                    <div
                      className={`d-flex align-items-start ${
                        index !== 0 ? "mt-4" : ""
                      } ${isLastItem ? "" : "inner"}`}
                    >
                      <img src={x.icon} />
                      <div className="ms-3">
                        <h5 className="font-face-Manrope-Bold  text-theme-white">
                          {x.title}
                        </h5>
                        <p className="text-theme-white font-face-Poppins-Medium  responsive-width">
                          {x.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FbExpertSubscribeForm;
