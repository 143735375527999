import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import navLogo from "../assets/images/webLogo.png";
import "./NavigationBar.css";
import { useLocation, useNavigate } from "react-router-dom";
import phoneimg from "../assets/images/receiverIcon.png";
import AnimatedCursor from "react-animated-cursor";
import AppLogoSvg from "../assets/svgs/logo.svg";

const NavigationBar = () => {
  const [isNavbarFixed, setNavbarFixed] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleNavigate = (path) => {
    navigate(path);
    setExpanded(false);
    setOpenDropDown(false);
    setActiveLink(path);

    window.scrollTo(0, 0);
  };

  const navigateToContactSection = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      const yOffset = -150; // Adjust this value depending on your layout
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
    setExpanded(false);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
    if (expanded) {
      setOpenDropDown(false);
    }
  };

  const handleDropDownToggle = () => {
    setOpenDropDown(!openDropDown);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold) {
        setNavbarFixed(true);
      } else {
        setNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const dropDownData = [
    {
      name: "Amazon",
      src: require("../assets/images/DropDownImage/1.png"),
      icon: require("../assets/images/fbadropdownicon.png"),
      route: "/amazon-fba/",
    },
    {
      name: "Shopify",
      src: require("../assets/images/DropDownImage/2.png"),
      icon: require("../assets/images/shopifyDropDownIcon.png"),
      route: "/shopify-automation/",
    },
    {
      name: "Tiktok",
      src: require("../assets/images/DropDownImage/3.png"),
      icon: require("../assets/images/tiktokDropdownIcon.png"),
      route: "/tiktok-automation/",
    },
    {
      name: "Walmart",
      src: require("../assets/images/DropDownImage/4.png"),
      icon: require("../assets/images/walmartDropdownIcon.png"),
      route: "/walmart-automation/",
    },
  ];

  const handleItemClick = (route) => {
    navigate(route);
  };

  const navigateContact = () => {
    const url = "tel:+13862449569";
    window.location.href = url;
  };

  const dropDownMain = () => {
    return (
      <div className="container mx-auto">
        <div className="custom-drop row">
          {dropDownData?.map((x, index) => {
            return (
              <div key={index} className="col-md-3 mb-2 text-white text-center">
                <div
                  onClick={() => handleItemClick(x.route)}
                  className="custom-main p-3"
                >
                  <img className="custom-image img-fluid" src={x.src} alt="1" />
                  <div className="d-flex align-items-center pt-3">
                    <img src={x.icon} alt="amzIcon" />
                    <div className="vertical-line" />
                    <small className="drop-text">{x.name}</small>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 990);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isMobileScreen) {
    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
        className={`bg-white ${isNavbarFixed ? "fixed-top" : ""}`}
      >
        {/* <AnimatedCursor
          innerSize={16}
          outerSize={16}
          color="254, 233, 166"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="button"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        /> */}
        <Container>
          <Navbar.Brand
            className="cursor-pointer"
            onClick={() => handleNavigate("/")}
          >
            <div className="d-flex align-items-center justify-content-between">
              <img src={AppLogoSvg} alt="navLogo" />

              {location.pathname === "/thankyou/" ||
              location.pathname === "/thankyou" ? null : (
                <div className="receiver-icon" onClick={navigateContact}>
                  <img src={phoneimg} alt="phone-img" />
                </div>
              )}
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto mx-5">
              <Nav.Link
                className={`font-face-Poppins-Medium ${
                  activeLink === "/" ? "nav-link-active" : ""
                }`}
                onClick={() => handleNavigate("/")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={`font-face-Poppins-Medium ${
                  activeLink === "/about/" ? "nav-link-active" : ""
                }`}
                onClick={() => handleNavigate("/about/")}
              >
                About
              </Nav.Link>

              <NavDropdown
                className="font-face-Poppins-Medium"
                title="Services"
                id="collapsible-nav-dropdown"
                show={openDropDown}
                onMouseEnter={() => setOpenDropDown(true)}
                onMouseLeave={() => setOpenDropDown(false)}
                onClick={handleDropDownToggle}
              >
                {/* <AnimatedCursor
                  innerSize={16}
                  outerSize={16}
                  color="254, 233, 166"
                  outerAlpha={0.2}
                  innerScale={0.7}
                  outerScale={5}
                  clickables={[
                    "a",
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="button"]',
                    'input[type="image"]',
                    "label[for]",
                    "select",
                    "textarea",
                    "button",
                    ".link",
                  ]}
                /> */}

                <NavDropdown.Item
                  onClick={() => handleNavigate("/amazon-fba/")}
                >
                  Amazon Automation
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => handleNavigate("/tiktok-automation/")}
                >
                  TikTok Automation
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => handleNavigate("/shopify-automation/")}
                >
                  Shopify Automation
                </NavDropdown.Item>

                <NavDropdown.Item
                  onClick={() => handleNavigate("/walmart-automation/")}
                >
                  Walmart Automation
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>

            {location.pathname === "/thankyou/" ||
            location.pathname === "/thankyou" ? null : (
              <Nav>
                {/* <div className="d-flex align-items-center">
                  <div onClick={navigateContact} className="me-4">
                    <img src={phoneimg} alt="phone-img" />
                  </div>
                  <button
                    className="global-light-btn mt-2 mt-lg-0 mt-md-0"
                    onClick={navigateToContactSection}
                  >
                    Contact Us
                  </button>
                </div> */}
                <button
                  className="global-light-btn mt-2 mt-lg-0 mt-md-0"
                  onClick={navigateToContactSection}
                >
                  Contact Us
                </button>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        expanded={expanded}
        onToggle={handleToggle}
        className={`bg-white ${isNavbarFixed ? "fixed-top" : ""}`}
      >
        {/* <AnimatedCursor
          innerSize={16}
          outerSize={16}
          color="254, 233, 166"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="button"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        /> */}
        <Container>
          <Navbar.Brand
            className="cursor-pointer"
            onClick={() => handleNavigate("/")}
          >
            <img src={AppLogoSvg} alt="navLogo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto mx-auto">
              <Nav.Link
                className={`font-face-Poppins-Medium ${
                  activeLink === "/" ? "nav-link-active" : ""
                }`}
                onClick={() => handleNavigate("/")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={`font-face-Poppins-Medium ${
                  activeLink === "/about/" ? "nav-link-active" : ""
                }`}
                onClick={() => handleNavigate("/about/")}
              >
                About
              </Nav.Link>

              <NavDropdown
                className="font-face-Poppins-Medium"
                title="Services"
                id="collapsible-nav-dropdown"
                show={openDropDown}
                onMouseEnter={() => setOpenDropDown(true)}
                onMouseLeave={() => setOpenDropDown(false)}
                onClick={handleDropDownToggle}
              >
                {/* <AnimatedCursor
                  innerSize={16}
                  outerSize={16}
                  color="254, 233, 166"
                  outerAlpha={0.2}
                  innerScale={0.7}
                  outerScale={5}
                  clickables={[
                    "a",
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="button"]',
                    'input[type="image"]',
                    "label[for]",
                    "select",
                    "textarea",
                    "button",
                    ".link",
                  ]}
                /> */}

                {dropDownMain()}
              </NavDropdown>
            </Nav>

            {location.pathname === "/thankyou/" ||
            location.pathname === "/thankyou" ? null : (
              <Nav>
                <div className="d-flex align-items-center">
                  <div onClick={navigateContact} className="me-4">
                    <img src={phoneimg} alt="phone-img" />
                  </div>
                  <button
                    className="global-light-btn mt-2 mt-lg-0 mt-md-0"
                    onClick={navigateToContactSection}
                  >
                    Contact Us
                  </button>
                </div>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
