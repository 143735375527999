import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const ProductExcellence = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const data = props?.productExcellenceData;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="product-excellence global-background">
      <div className="container py-5">
        {data?.map((x, index) => {
          return (
            <div key={index} className="row">
              <div className="col-md-6 d-flex flex-column justify-content-center">
                <h1
                  className="text-theme-white font-face-Bold"
                  data-aos="zoom-in"
                  data-aos-duration="900"
                >
                  {x.productTitleOne}{" "}
                  <span className="d-md-block">{x.productTitleTwo}</span>
                </h1>
                <p
                  className="text-theme-light-white font-face-Poppins-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {x.productDescription}
                </p>

                {x.featureProducts?.map((y, index) => {
                  return (
                    <div key={index}>
                      <div
                        key={index}
                        className="d-flex align-items-start py-4"
                      >
                        <img src={y.icon} alt="ProductIcon" />
                        <div className="ms-4">
                          <h5 className="text-theme-white font-face-Manrope-Extra-Bold">
                            {y.featureHeading}
                          </h5>
                          <p className="font-face-Poppins-Regular text-theme-light-white">
                            {y.description}
                          </p>
                        </div>
                      </div>
                      {index !== x.featureProducts.length - 1 && (
                        <hr className="text-theme-white" />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                {windowWidth <= 540 ? (
                  <img
                    src={x.productImageMobile}
                    alt="ProductExcellenceImageMobile"
                    className="img-fluid"
                  />
                ) : (
                  <img
                    src={x.productImage}
                    alt="ProductExcellenceImage"
                    className="img-fluid"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductExcellence;
