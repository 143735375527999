import React, { useEffect } from "react";
import HeroImage from "../components/HeroImage";
import BusinessSolutionCard from "../components/BusinessSolutionCard";
import StoryCard from "../components/StoryCard";
import VideoTestimonial from "../components/VideoTestimonial";
import ResultDrivenSection from "../components/ResultDrivenSection";
import ServiceAutomation from "../components/ServiceAutomation";
import SolutionCreator from "../components/SolutionCreator";
import PriceOptionsCards from "../components/PriceOptionsCards";
import solutionCardImage from "../assets/images/120 (1).jpg";
import DynamicSeo from "../utils/DynamicSeo.js";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const quoteText =
    "I Believe Success in E-commerce is Not Just About Selling, It's About Creating Value and Lasting Impact";

  const FAQDATA = [
    {
      heading: "What is your best approach to selling products on Amazon?",
      description:
        "Amazon is a great place to reach an untapped market and increase your revenues through product listing optimization, digital marketing, enhanced brand amazon content/A+ content, sponsored ads, engaging sales copy, attractive product descriptions, attractive product images, Amazon fulfillment, and many other methods. Our marketing services for amazon promise scaling of your business driven by sales.",
    },
    {
      heading: "How can FBAXperts improve my product rankings on Amazon?",
      description:
        "Using advanced SEO techniques and data-driven strategies, The FBAXperts improves product rankings on Amazon. We analyze market trends and optimize listings with impactful keywords, ensuring your products attract a broader audience.",
    },
    {
      heading:
        "What kind of results can I expect from using FBAXperts services?",
      description:
        "Choosing FBAXperts means seeing a noticeable increase in product visibility, improved rankings, and a boost in sales. Our clients experience enhanced market performance thanks to our targeted optimization and marketing strategies.",
    },
    {
      heading:
        "Do you offer tailored strategies for different types of products?",
      description:
        "The FBAXperts provide customized strategies for various product types. Recognizing the unique demands of each niche, we adapt our approach to your specific product category, ensuring the most effective marketing and optimization.",
    },
    {
      heading: "How long do you take to set up an online E-commerce store?",
      description:
        "Creating an online e-commerce store takes approximately three to four weeks for a new business to set up an e-commerce store. This goal is achievable for any company interested in FBAXperts to help you set up your online store within this period because we have professional marketers and business advisors with immense experience. Hiring our services, you will get only high quality work and the best practices that will contribute to the success of your e-commerce business.",
    },
  ];

  return (
    <div>
      <DynamicSeo
        title="E-commerce Development Company| FBAXperts"
        description="Looking for ways to take your business to the next level in the realm of E-commerce? Become our partner at FBAXperts - The reliable business for Amazon, Walmart, Shopify & TikTok."
      />
      <HeroImage />
      <BusinessSolutionCard />
      <ServiceAutomation />
      <SolutionCreator
        quoteText={quoteText}
        solutionCardImage={solutionCardImage}
      />
      <PriceOptionsCards />
      {/* <StoryCard /> */}
      <VideoTestimonial />

      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default HomePage;
