import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonial.css";
import seperator from "../assets/images/Separator.png";

const Testimonial = () => {
  const testimonialData = [
    // {
    //   starIcon: require("../assets/images/startImage.png"),
    //   description:
    //     "Recently, we have partnered with FBAXperts for our Amazon business. Their innovative strategies and detailed approach have significantly boosted our sales. We've seen a remarkable improvement in our performance metrics, and their team is always ready to support us.",
    //   userIcon: require("../assets/images/Image.png"),
    //   userName: "John Doe",
    //   userDesignation: "E-commerce Manager at TrendyTech",
    // },
    {
      starIcon: require("../assets/images/startImage.png"),
      description:
        "Our experience with FBAXperts has been phenomenal. Their automation solutions have streamlined our operations, saving us time and effort. Their expertise and dedication have led to a noticeable increase in our conversion rates and overall sales.",
      userIcon: require("../assets/images/Name.png"),
      userName: "Oliver Smith",
      userDesignation: "Founder of ShopEase",
    },
    {
      starIcon: require("../assets/images/startImage.png"),
      description:
        "Partnering with FBAXperts for our business was the best decision we made. Their optimization techniques and inventory management have drastically improved our product visibility and sales. The team's professionalism and commitment to our success are truly commendable.",
      userIcon: require("../assets/images/Image (1).png"),
      userName: "Michael Johnson",
      userDesignation: "Director of Online Sales at UrbanOutfitters",
    },
    {
      starIcon: require("../assets/images/startImage.png"),
      description:
        "FBAXperts strategies have been a game-changer for our brand. They helped us harness viral trends effectively, which has significantly expanded our reach and boosted our revenue. Their creative approach and deep understanding of the platform are impressive.",
      userIcon: require("../assets/images/Image (3).png"),
      userName: "Emily Brown",
      userDesignation: "CEO of TikTrends",
    },
    {
      starIcon: require("../assets/images/startImage.png"),
      description:
        "FBAXperts provided exceptional support across all our E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. Their comprehensive services and expert guidance have been instrumental in our business growth. We're thrilled with the results and highly recommend their services to any E-commerce business looking to scale.",
      userIcon: require("../assets/images/Image.png"),
      userName: "Sarah Wilson",
      userDesignation: "Marketing Director at ShopSmart",
    },
  ];
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    autoplay: true,
    arrows: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="testimonial-main global-background">
      <div className="container py-5">
        <h1 className="text-theme-white text-center font-face-Bold">
          What They Saying About Us
        </h1>

        <div className="row py-5 mx-auto">
          <Slider {...settings}>
            {testimonialData?.map((x, index) => {
              return (
                <div
                  key={index}
                  className="col-md-6 d-flex flex-column justify-content-center align-items-center"
                >
                  <img src={x.starIcon} alt="starImage" />
                  <p className="text-theme-light-white text-center px-md-5 m-0 py-4 font-face-Poppins-Regular px-2">
                    {x.description}
                  </p>

                  <div className="d-flex align-items-center justify-content-center">
                    <img src={x.userIcon} alt="userImage" />

                    <div className="ms-3">
                      <h5 className="text-light-blue font-face-Poppins-Regular m-0">
                        {x.userName}
                      </h5>
                      <p className="font-face-Poppins-Regular text-theme-light-white m-0">
                        {x.userDesignation}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="text-center">
          <img className="w-75" src={seperator} alt="sperate" />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
