import React, { useEffect } from "react";
import bannerImage from "../assets/images/aboutBg.png";
import rightImage from "../assets/images/aboutUsRightImage.png";
import AboutBanner from "../components/AboutBanner";
import SolutionCreator from "../components/SolutionCreator";
import solutionCardImage from "../assets/images/aboutSolutionCreator.png";
import WorkingStandards from "../components/WorkingStandards";
import ResultDrivenSection from "../components/ResultDrivenSection";
import CoreValuesSlider from "../components/CoreValuesSlider";
import Testimonial from "../components/Testimonial";
import DynamicSeo from "../utils/DynamicSeo";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "Our Every Success";
  const bannerSecondaryHeading = "Has History";

  const quoteText =
    "I Believe Success in E-commerce is Not Just About Selling, It's About Creating Value and Lasting Impact.";

  const FAQDATA = [
    {
      heading:
        "Do I really need Amazon Marketing Service Professionals for my business?",

      description:
        "An Amazon marketing service specialist will assist you to promote your product, effectively manage your online store, and bring more visitors to your product website, no matter whether you are a small seller or an established business. Consequently, you will be able to advertise your products effectively and adhere to all Amazon guidelines.",
    },
    {
      heading: "How do I know I have an effective Amazon strategy?",

      description:
        "You have an effective Amazon strategy in mind when your product is always ahead in the search list and thus continues to be more visible to consumers. Furthermore, your ad campaigns should be highly effective in terms of ROI, and, there should be a constant increase in the customer reviews and ratings. Periodically reviewing important activity measurements and adjusting the technique based on the numbers collected is essential for strategy optimization.",
    },
    {
      heading:
        "I have a successful Amazon store. Do I need to hire Amazon Experts for my online business?",

      description:
        "Hiring Amazon marketing experts can be advantageous for your company even when you already have a successful Amazon store. Advertisers can enhance your listings, advertise properly when it comes to campaigns, and offer you information on recent market and ad strategies. This is because they have specialized knowledge and will be able to attract more people into your store thus enhance sales revenue and growth.",
    },
    {
      heading:
        "How does FBAXperts ensure the growth of my E-commerce business?",

      description:
        "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
    },
    {
      heading:
        "Can FBAXperts handle multiple E-commerce platforms simultaneously?",

      description: `Yes, FBAExperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.`,
    },
  ];
  return (
    <div>
      <DynamicSeo
        title="E-commerce Development Company| FBAXperts "
        description="Want to sky-rocket your business in the E-commerce market? Partner with us at FBAXperts -  Your trusted business for Amazon, Walmart, Shopify & TikTok."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        rightImage={rightImage}
      />
      <SolutionCreator
        quoteText={quoteText}
        solutionCardImage={solutionCardImage}
        rightImage={rightImage}
      />
      <WorkingStandards />
      <CoreValuesSlider />
      <ResultDrivenSection FAQDATA={FAQDATA} />
      <Testimonial />
    </div>
  );
};

export default AboutPage;
