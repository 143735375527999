import React from "react";
import MetaTags from "react-meta-tags";

const DynamicSeo = (props) => {
  return (
    <>
      <MetaTags>
        <meta charSet="utf-8" />
        <title>{props?.title}</title>
        <meta name="description" content={props?.description} />
        {/* <meta property="og:title" content="Facebook" />
        <meta
          property="og:image"
          content="../assets/images/footerImages/fb.png"
        />
        <meta
          property="og:url"
          content="https://www.facebook.com/profile.php?id=100090258982388"
        />

        <meta property="og:title" content="Instagram" />
        <meta
          property="og:image"
          content="../assets/images/footerImages/insta.png"
        />
        <meta
          property="og:url"
          content="https://www.instagram.com/amzifysolutions_?igsh=ZG82NHU4aGU0Ynoy"
        />

        <meta property="og:title" content="linkedin" />
        <meta
          property="og:image"
          content="../assets/images/footerImages/lk.png"
        />
        <meta
          property="og:url"
          content="https://www.linkedin.com/company/92838097/admin/feed/posts/"
        ></meta> */}
      </MetaTags>
    </>
  );
};

export default DynamicSeo;
