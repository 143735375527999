import React, { useEffect } from "react";
import bannerImage from "../assets/images/TiktokAutomationBanner.png";
import rightImage from "../assets/images/tiktokeBannerRight.png";
import AboutBanner from "../components/AboutBanner";
import CustomServices from "../components/CustomServices";
import FbExpertsPortfolio from "../components/FbExpertsPortfolio";
import ProductExcellence from "../components/ProductExcellence";
import ResultDrivenSection from "../components/ResultDrivenSection";
import DynamicSeo from "../utils/DynamicSeo";

const TiktokAutomationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "We Always Give The Best";
  const bannerSecondaryHeading = "Tiktok Automation";
  const bannerTertiaryHeading = "Service To You";

  const customServiceData = [
    {
      headingOne: "Maximize Your Brand's ",
      headingSecond: "Impact with Our TikTok",
      headingThird: "Automation Services",
      descriptionOne:
        "Our TikTok Automation Services are designed to elevate your brand's presence and drive engagement on this rapidly growing platform. We offer a range of services, from account setup and management to content creation and audience targeting, all tailored to meet your specific business goals.",
      descriptionTwo:
        "Our team of experts is well-versed with the latest TikTok trends and algorithms, ensuring that your brand stays relevant and visible to your target audience. Whether you're looking to launch a new product, increase brand awareness, or drive sales, we can help you achieve your goals.",
      serviceImage: require("../assets/images/tiktokService1.png"),
    },
    {
      headingOne: "Reach Unprecedented Heights",
      headingSecond: "with our TikTok Automation Services",
      descriptionOne:
        "At FBAXperts, our team is driven by a passion for excellence, ensuring that each project is executed with meticulous attention to detail and creativity. We understand the importance of staying ahead, which is why we constantly strive to deliver cutting-edge solutions customized to your unique brand's needs.",
      descriptionTwo:
        "Our approach is rooted in collaboration and transparency, ensuring that you are involved in every step of the process. From crafting engaging content to optimizing your TikTok strategy, we work tirelessly to ensure that your brand's message resonates with your target audience. ",
      serviceImage: require("../assets/images/tiktokService2.png"),
    },
  ];

  const productExcellenceData = [
    {
      productTitleOne: "We Offer Impeccable Services",
      productTitleTwo: "for Your TikTok Shop",
      productDescription:
        "We offer dynamic solutions designed to skyrocket your brand's presence on TikTok. Our expert team ensures every aspect of your store is handled with precision and creativity. ",
      productIconOne: require("../assets/images/ProductExcellenceIcon1.png"),
      featureProducts: [
        {
          icon: require("../assets/images/ProductExcellenceIcon1.png"),
          featureHeading: "Data-Driven Optimization",
          description:
            "We use data-driven strategies to optimize your TikTok campaigns, ensuring optimal revenue generation.",
        },
        {
          icon: require("../assets/images/ProductExcellenceIcon2.png"),
          featureHeading: "Strategic Audience Engagement",
          description:
            "We craft strategic engagement plans that maximize your reach and foster strong connections with your audience.",
        },
      ],

      productImage: require("../assets/images/ProductExcellenceImage2.png"),
      productImageMobile: require("../assets/images/ProductExcellenceImage2Mobile.png"),
    },
  ];

  const portfolioImags = [
    {
      revenueImage: require("../assets/images/TikTok/1.png"),
    },
    {
      revenueImage: require("../assets/images/TikTok/2.png"),
    },
    {
      revenueImage: require("../assets/images/TikTok/3.png"),
    },
    {
      revenueImage: require("../assets/images/TikTok/4.png"),
    },
    {
      revenueImage: require("../assets/images/TikTok/5.png"),
    },
    {
      revenueImage: require("../assets/images/TikTok/6.png"),
    },
  ];

  const FAQDATA = [
    {
      heading: "How TikTok Automation can be useful for my brand?",

      description:
        "TikTok automation can be beneficial for your brand because it allows you to manage your TikTok Shop more effectively, thus expanding your business reach, improving user base and enhancing overall TikTok strategy in terms of visibility and activity. Let’s collaborate today and take your business on higher levels in the competitive world.",
    },
    {
      heading:
        "What specific action plans would you employ to improve my TikTok shop performance? ",

      description:
        "At FBAXperts, we employ various approaches to optimize your TikTok shop experience. We enhance the placement, promote sale events, and increases product exposure based on specific metrics.Also, our experts help in content creation, making interesting content related to your channel or brand, incorporating trending challenges & lastly, using advertisements to expand your reach.",
    },
    {
      heading: "How does FBAXperts’ TikTok Automation service stand out?",

      description:
        "TikTok Automation Service of FBAXperts is different from others because we offer highly customized strategies and services accounting for the specific features of the TikTok platform. In essence, we strive to provide the best strategy to optimize your data, content, and audience to secure the best engagement for your brand. Our dedication to staying ahead of trends and providing customized solutions sets us apart.",
    },
    {
      heading:
        "How do you integrate TikTok Shop with my Social Media Strategy?",

      description:
        "As an extension of your social media marketing campaign, we synchronize TikTok shop with your objectives and promotions on other social media platforms. By creating compatibility and having the complementary use of cross platform promotions, we establish a strong social media communication that positively contributes to your brand awareness.",
    },
    {
      heading:
        "Can You Share Examples of Brands That Have Benefited from Your TikTok Automation Services? ",

      description: `Absolutely! Our exquisite services have enabled many brands to attain enhanced growth and interactions on the TikTok platform. Our past campaigns include from creating simple local-based posts to elaborate influencer partnerships to raise awareness and increase sales. Our motive is to enhance your brand’s visibility and sales of your TikTok Shop. `,
    },
  ];
  return (
    <div>
      <DynamicSeo
        title="TikTok Automation Services | FBAXperts"
        description="Want to enhance your TikTok Shop? Choose FBAXperts for efficient and effective TikTok automation solutions to improve the performance and reach of your account."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        bannerTertiaryHeading={bannerTertiaryHeading}
        rightImage={rightImage}
      />
      <CustomServices customServiceData={customServiceData} />
      <FbExpertsPortfolio portfolioImags={portfolioImags} />
      <ProductExcellence productExcellenceData={productExcellenceData} />
      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default TiktokAutomationPage;
