import React from "react";

const ServiceAutomation = () => {
  const services = [
    {
      icon: require("../assets/images/amzIcon.png"),
      title: "Amazon FBA Automation",
      descriptionFirst:
        "Transform your Amazon business with FBAXperts' Amazon FBA automation services. We handle everything from inventory management to order fulfillment, so you can focus on growing your brand.",
      descriptionSecond:
        "Say goodbye to the headaches of manual tasks. Our FBA automation ensures your operations are efficient and error-free",
    },
    {
      icon: require("../assets/images/tiktokicon.png"),
      title: "TikTok Automation",
      descriptionFirst:
        "Transform your TikTok shop into a thriving online store with FBAXperts' automation services. We take care of inventory management, order processing, and customer interactions, allowing you to provide a seamless shopping experience. With our expertise, you can maximize your TikTok shop's potential and drive sales like never before.",
      descriptionSecond:
        "Let us handle the technicalities so you can focus on showcasing your products and growing your business on TikTok.",
    },
    {
      icon: require("../assets/images/automationIcon.png"),
      title: "Shopify Automation Services",
      descriptionFirst:
        "Take the hassle out of managing your store with FBAXperts' Shopify automation services. From updating product listings to handling orders, we ensure your store operates smoothly. This lets you focus on what matters most- creating great products and connecting with your customers.",
      descriptionSecond:
        " We streamline your processes, cut down on manual tasks, and boost your store's performance. With us, you'll save time and see your business grow faster & effectively.",
    },
    {
      icon: require("../assets/images/martIcon.png"),
      title: "Walmart Automation",
      descriptionFirst:
        "Experience the future of e-commerce with FBAXperts' Walmart automation services. We take care of all the tedious tasks, so you don’t have to. From real-time inventory updates to automated customer service, we ensure your Walmart store runs smoothly and efficiently, helping you achieve your business goals effortlessly.",
      descriptionSecond:
        "Let us streamline your operations, improve your efficiency, and boost your sales",
    },
  ];
  return (
    <div className="service-automation global-background">
      <div className="container py-5">
        {services?.map((x, index) => {
          return (
            <React.Fragment key={index}>
              <div className="row py-3">
                <div className="col-md-4 ">
                  <div className="d-flex align-items-center">
                    <img src={x.icon} alt="tiktokIcon" />
                    <h4 className="text-theme-white font-face-Bold ms-4 my-0">
                      {x.title}
                    </h4>
                  </div>
                </div>
                <div className="col-md-4 px-lg-4 py-2 py-lg-0 py-md-0">
                  <div className="text-theme-light-white font-face-Poppins-Medium">
                    {x.descriptionFirst}
                  </div>
                </div>
                <div className="col-md-4 px-lg-4 py-2 py-lg-0 py-md-0">
                  <div className="text-theme-light-white font-face-Poppins-Medium">
                    {x.descriptionSecond}
                  </div>
                </div>
              </div>
              <hr className="text-theme-white" />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceAutomation;
