import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const FbExpertsPortfolio = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1200 });
  });

  let temporarayImageStyle = {
    borderRadius: "20px",
  };
  return (
    <div className="fbexperts-portfolio global-background">
      <div className="container py-5">
        <h1
          className="text-theme-white font-face-Bold text-center"
          data-aos="zoom-in"
          data-aos-duration="1200"
        >
          Our Valuable Workfolio{" "}
          <span className="d-md-block">Result Driven</span>
        </h1>

        <div className="row px-lg-5 pt-4">
          {props?.portfolioImags?.map((x, index) => {
            return (
              <div key={index} className="col-md-4 mb-3 text-center">
                <img
                  style={temporarayImageStyle}
                  className="img-fluid"
                  src={x.revenueImage}
                  alt="revenue image"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FbExpertsPortfolio;
