import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const WorkingStandards = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const standards = [
    {
      cardImage: require("../assets/images/workingStandards1.png"),
      title: "Elevate Your Buisness",
      description:
        "We specialize in transforming your E-commerce store into a thriving business, leveraging our expertise to drive significant growth.",
      icon: require("../assets/images/homeIcon.png"),
    },
    {
      cardImage: require("../assets/images/workingStandards2.png"),
      title: "Finest Online Store",
      description:
        "Our team ensures your online store stands out with optimized listings, & strategic enhancements that attract and retain customers.",
      icon: require("../assets/images/standardIcon2.png"),
    },
    {
      cardImage: require("../assets/images/workingStandards3.png"),
      title: "Flexible Working",
      description:
        "We adapt to your business needs, providing flexible solutions that accommodate your unique requirements & ensure smooth operations.",
      icon: require("../assets/images/standardIcon3.png"),
    },
    {
      cardImage: require("../assets/images/workingStandards4.png"),
      title: "Store Enhancement",
      description:
        "Enhance your store's performance with our targeted strategies that focus on improving user experience, boosting sales, & maximizing ROI.",
      icon: require("../assets/images/standardIcon4.png"),
    },
    {
      cardImage: require("../assets/images/workingStandards5.png"),
      title: "Online Product Presence",
      description:
        "Increase your product visibility and reach with our expert optimization techniques, ensuring your products are easily found by potential customers.",
      icon: require("../assets/images/standardIcon5.png"),
    },
    {
      cardImage: require("../assets/images/workingStandards6.png"),
      title: "Clean Workflow",
      description:
        "We maintain a streamlined and efficient workflow, minimizing disruptions and ensuring consistent progress & timely delivery of results.",
      icon: require("../assets/images/standardIcon6.png"),
    },
  ];
  return (
    <div className="working-standards global-background">
      <div className="container py-5">
        <div className="text-center">
          <h1
            className="text-theme-white font-face-Bold"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            Our Working Standards
          </h1>
          <p
            className="text-theme-light-white font-face-Poppins-Medium"
            data-aos="zoom-in"
            data-aos-duration="1200"
          >
            Our working standards are designed to ensure the highest level of
            efficiency, reliability, and success for your E-commerce business.
            We combine innovative strategies with industry best practices to
            provide a seamless experience for our clients.
          </p>
          <p
            className="text-theme-light-white font-face-Poppins-Medium"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            We take pride in our meticulous approach, which involves thorough
            planning, execution, and continuous optimization. Our team of
            experts is dedicated to helping you achieve your business goals,
            whether it's launching a new store or scaling an existing one.
          </p>
        </div>

        <div className="row pt-4">
          {standards?.map((x, index) => {
            return (
              <div key={index} className="col-md-4 mt-5">
                <div>
                  <img
                    className="img-fluid"
                    src={x.cardImage}
                    alt="standards"
                  />

                  <div className="d-flex align-items-center pt-4">
                    <img src={x.icon} alt="homeIcon" />
                    <h4 className="ms-3 text-theme-white font-face-Bold m-0">
                      {x.title}
                    </h4>
                  </div>

                  <p className="m-0 pt-3 font-face-Poppins-Medium text-theme-light-white">
                    {x.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkingStandards;
