import React, { useEffect } from "react";
import AboutBanner from "../components/AboutBanner";
import bannerImage from "../assets/images/privacyPolicyBanner.png";
import rightImage from "../assets/images/privacyPolicyRight.png";
import ResultDrivenSection from "../components/ResultDrivenSection";
import Aos from "aos";
import "aos/dist/aos.css";
import DynamicSeo from "../utils/DynamicSeo";

const PrivayPolicy = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "Your Privacy is Our";
  const bannerSecondaryHeading = "Priority";

  const content = [
    {
      heading: "Personal Data Handling",
      paragraph:
        "We collect essential details, including your name, email, phone number, and billing address, to ensure the seamless delivery of our services. Your data is your asset, and we will never share it without your explicit consent. Though, we may need to share with third-party service providers who assist us.",
    },
    {
      heading: "Data Security",
      paragraph:
        "We have implemented robust measures to safeguard your personal information against unauthorized access, alteration, & disclosure. Utilizing secure socket layer (SSL) encryption for the protection of your payment information during transmission is one of the steps we take.",
    },
    {
      heading: "Cookie Usage",
      paragraph:
        "We use cookies to gather information regarding your interaction with our website and services, enhancing your user experience. You can decline the use of cookies, but this may affect your access to specific features or services on our website",
    },
    {
      heading: "Third-Party Sharing",
      paragraph:
        "In certain circumstances, we share your personal data with trusted third-party service providers to facilitate operations like payment processing and customer support. These third parties are bound by confidentiality agreements and can only use your data for specified purposes.",
    },
    {
      heading: "How to Claim Your Refund",
      paragraph:
        "To request a refund, kindly go to the Contact Us page and provide the necessary information about your order as well as a short description of why you want your refund. Our dedicated support staff is always at your service to help you with your concerns through this process.",
      paragraph2:
        "You can also get in touch with us through email, phone call, and through the website contact page. Kindly fill all the required details where necessary to ensure the process is quick as possible with the order number required. Once your refund request is received by us, we scrutinize the request to ensure that it meets some of the criteria which are set down in our refund policy.",
      paragraph3:
        "Regarding the status or your request, please be advised that we may request from you additional information if necessary. If your refund application is granted, it will be effected and the amount will be credited back to your initial mode of payment in the stipulated period.",
      paragraph4:
        "Here at FBAXperts, we always do our best to ensure that the refund process is as simple as can be and that it testifies to our dedication to your complete satisfaction.",
    },
  ];

  const FAQDATA = [
    {
      heading: "What services does FBAXperts offer for E-commerce businesses?",

      description:
        "At FBAXperts, we provide specialized services for Amazon, Shopify, Walmart, and TikTok. Our services include account setup and management, inventory optimization, product listing and SEO, advertising and marketing strategies, and automation solutions to streamline your operations and drive growth.",
    },
    {
      heading: "How do I get started with FBAXperts?",
      description:
        "Getting started with FBAXperts is simple. Contact us through our website or call our customer service team. We will discuss your business needs, develop a tailored strategy, and guide you through the implementation process to ensure your success.",
    },
    {
      heading:
        "How does FBAXperts ensure the growth of my E-commerce business?",
      description:
        "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
    },
    {
      heading:
        "Can FBAXperts handle multiple E-commerce platforms simultaneously?",
      description:
        "Yes, FBAXperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.",
    },
    {
      heading:
        "How does FBAXperts stay updated with the latest E-commerce trends?",

      description: `Our team at FBAXperts continuously monitors the latest trends, updates, and best practices in the E-commerce industry. We regularly attend industry conferences, engage in professional development, and leverage advanced tools and technologies to ensure our clients benefit from the most current and effective strategies.`,
    },
  ];
  return (
    <div className="privacy-policy global-background">
      <DynamicSeo
        title="Privacy Policy | FBAXperts"
        description="We prioritize the protection of your personal information, outlining our practices, procedures, and assurances regarding data privacy and security at FBAXperts."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        rightImage={rightImage}
      />

      <div className="container py-5">
        {content?.map((x, index) => {
          return (
            <div key={index} className="pt-5">
              <h3
                className="text-theme-second font-face-Poppins-Bold"
                data-aos="zoom-in"
                data-aos-duration="700"
              >
                {x.heading}
              </h3>
              <p
                className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                data-aos="zoom-in"
                data-aos-duration="600"
              >
                {x.paragraph}
              </p>
              {x.paragraph2 ? (
                <p
                  className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                  data-aos="zoom-in"
                  data-aos-duration="550"
                >
                  {x.paragraph2}
                </p>
              ) : null}
              {x.paragraph3 ? (
                <p
                  className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  {x.paragraph3}
                </p>
              ) : null}
              {x.paragraph4 ? (
                <p
                  className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                  data-aos="zoom-in"
                  data-aos-duration="450"
                >
                  {x.paragraph4}
                </p>
              ) : null}
            </div>
          );
        })}
      </div>

      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default PrivayPolicy;
