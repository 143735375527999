import React, { useEffect } from "react";
import bannerImage from "../assets/images/AmazonFBaBanner.png";
import rightImage from "../assets/images/AmazonFBARight.png";
import AboutBanner from "../components/AboutBanner";
import CustomServices from "../components/CustomServices";
import FbExpertsPortfolio from "../components/FbExpertsPortfolio";
import ProductExcellence from "../components/ProductExcellence";
import ResultDrivenSection from "../components/ResultDrivenSection";
import DynamicSeo from "../utils/DynamicSeo";

const AmazonFBAPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "We Always Give";
  const bannerSecondaryHeading = "The Best Amazon FBA";
  const bannerTertiaryHeading = "Service To You";

  const customServiceData = [
    {
      headingOne: "Revolutionize Your E-commerce",
      headingSecond: "Business with Premium Amazon FBA ",
      headingThird: "Automation Services",
      descriptionOne:
        "Our team of seasoned professionals is dedicated to optimizing your Amazon store, streamlining operations, and driving substantial revenue growth. We combine advanced automation technologies to ensure your business scales effortlessly, allowing you to focus on strategic growth while we handle the complexities.",
      descriptionTwo:
        "Our services are designed to provide seamless integration, from inventory management to order fulfillment, ensuring a hassle-free experience. With our commitment to excellence and tailored solutions, we deliver exceptional results and setting your business on a trajectory for sustained success. ",
      serviceImage: require("../assets/images/fbaService1.png"),
    },
    {
      headingOne: "Achieve Massive Success on",
      headingSecond: "Amazon with FBAXperts",
      descriptionOne:
        "In the fast-paced world of e-commerce, mastering Amazon FBA can seem overwhelming. FBAXperts is your trusted partner, offering top-tier automation services to simplify your journey. Our comprehensive solutions, from inventory management to store optimization, ensure your business thrives effortlessly.",
      descriptionTwo:
        "With us, navigating the complexities of Amazon FBA becomes a breeze. We provide seamless, efficient yet scalable services that elevate your selling experience and drive remarkable growth. Trust us for your Amazon business and let us assist you in scaling your business to new heights.",
      serviceImage: require("../assets/images/fbaService2.png"),
    },
  ];

  const productExcellenceData = [
    {
      productTitleOne: "We Provide the Best Services",
      productTitleTwo: "for Your Product",
      productDescription:
        "At FBAXperts, we offer top-tier solutions designed to streamline your Amazon business. Our expert team ensures every aspect operates flawlessly & seamlessly.",
      productIconOne: require("../assets/images/ProductExcellenceIcon1.png"),
      featureProducts: [
        {
          icon: require("../assets/images/ProductExcellenceIcon1.png"),
          featureHeading: "Expertise in Inventory Management",
          description:
            "Our inventory management services ensure your products are always in stock and ready to meet customer demand.",
        },
        {
          icon: require("../assets/images/ProductExcellenceIcon2.png"),
          featureHeading: "Superior Order Fulfillment Solutions",
          description:
            "We guarantee fast, reliable delivery, ensuring your customers receive their orders promptly and in perfect condition.",
        },
      ],

      productImage: require("../assets/images/ProductExcellenceImage1.png"),
      productImageMobile: require("../assets/images/ProductExcellenceImage1Mobile.png"),
    },
  ];

  const portfolioImags = [
    {
      revenueImage: require("../assets/images/Amazon/1.png"),
    },
    {
      revenueImage: require("../assets/images/Amazon/2.png"),
    },
    {
      revenueImage: require("../assets/images/Amazon/3.png"),
    },
    {
      revenueImage: require("../assets/images/Amazon/4.png"),
    },
    {
      revenueImage: require("../assets/images/Amazon/5.png"),
    },
    {
      revenueImage: require("../assets/images/Amazon/6.png"),
    },
  ];
  const FAQDATA = [
    {
      heading:
        "What opportunities does Amazon FBA Automation services provide to maximize my profit? ",

      description:
        "Amazon FBA automation is about order fulfillment, inventory management and effortless shipping which means it is all about saving the time and efforts. Our experts will help you in managing inventories and in determining the appropriate stock levels that needs to be replenished and adjust prices to suit the current sales performance.",
    },
    {
      heading:
        "What makes FBAXperts different from other Amazon FBA automation services providers? ",

      description:
        "FBAXperts differentiates itself through individualized approaches stemming from a knowledge of how Amazon works and what is trending in the modern world of e-commerce. Our approach is centered on product exposure and our aim is to empower businesses of all sizes in achieving massive sales & revenue. ",
    },
    {
      heading:
        "Which kind of businesses should use Amazon FBA automation services? ",

      description:
        "Utilizing Amazon FBA automation services is advantageous for almost every business. Whether you are a new seller who wants to escalate in the rivalrous domain shortly or you are an already established brand that is looking forward to expand further on the Amazon market, then you should consider these services. Our premium services are designed to help you grow as fast as you want and as big as you wish on the Amazon marketplace.",
    },
    {
      heading:
        "What measures do you take to ensure that you follow Amazon policies and regulations? ",

      description:
        "Utilizing Amazon FBA automation services is advantageous for almost every business. Whether you are a new seller who wants to escalate in the rivalrous domain shortly or you are an already established brand that is looking forward to expand further on the Amazon market, then you should consider these services. Our premium services are designed to help you grow as fast as you want and as big as you wish on the Amazon marketplace.",
    },
    {
      heading:
        "Is it possible that I can track the performance of my products and sales through your services? ",

      description: `Yes, FBAXperts does offer several detailed analysis and reports through which you can monitor several aspects, such as product specific performance & sales. These tools provide information about things like sales, customers, and inventory which are very important in any business. Using these findings, Amazon sellers can objectively evaluate the effectiveness of their strategies, define improvement opportunities, and enhance their activity for superior performance.`,
    },
  ];

  return (
    <div>
      <DynamicSeo
        title="Amazon FBA Automation Services | FBAXperts"
        description="Want to take your Amazon business to extensive levels? Choose FBAXperts for attaining peak financial performance & making maximum financial gain in the cutthroat market."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        bannerTertiaryHeading={bannerTertiaryHeading}
        rightImage={rightImage}
      />
      <CustomServices customServiceData={customServiceData} />
      <FbExpertsPortfolio portfolioImags={portfolioImags} />
      <ProductExcellence productExcellenceData={productExcellenceData} />
      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default AmazonFBAPage;
