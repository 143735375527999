import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

const SolutionCreator = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const { quoteText } = props;
  // Split the text into an array of words
  const splitArray = quoteText.split(" ");

  // Wrap specific indices with a span for styling
  const styledArray = splitArray?.map((word, index) => {
    if ([9, 10, 11, 17].includes(index)) {
      return `<span class="text-theme">${word}</span>`;
    }
    return word;
  });

  // Join the array back into a string
  const styledText = styledArray.join(" ");
  return (
    <div className="solution-creator global-background">
      <div className="container py-3 py-lg-5 py-md-5">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h1
              className="text-theme-white font-face-Bold responsive-width"
              dangerouslySetInnerHTML={{ __html: `"${styledText}"` }}
              data-aos="zoom-in"
              data-aos-duration="800"
            />
            <p
              className="text-theme-light-white font-face-Poppins-Medium pt-4"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              At FBAXperts, we believe that with the right strategy and
              unwavering dedication, anyone can take their business on
              significant levels. Our mission is to empower entrepreneurs to
              achieve success through tailored solutions for Amazon, Walmart,
              Shopify, & TikTok.
            </p>
            <p
              className="text-theme-light-white font-face-Poppins-Medium"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Our expert team ensures your products stand out. We provide
              comprehensive support and innovative strategies that propel your
              business forward, ensuring sustained growth and unmatched success.
            </p>

            <div className="d-flex align-items-center pt-4">
              <p className="text-theme-white m-0 font-face-Aguafina-Script-Regular">
                Orion Sterling
              </p>
              <p className="text-theme-light-white m-0 font-face-Poppins-Regular">
                ~ founder of FBAXPERTS
              </p>
            </div>
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mt-3 mt-lg-0 mt-md-0">
            <img
              className="img-fluid"
              src={props?.solutionCardImage}
              alt="creativeImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionCreator;
