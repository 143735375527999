import React, { useEffect } from "react";
import "./Footer.css";
import FaceBookIcon from "../assets/svgs/facebook.svg";
import linkdinIcon from "../assets/svgs/linkdin.svg";
import instagramIcon from "../assets/svgs/instagram.svg";
import youtubeIcon from "../assets/images/youtubeIcon.png";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import AppLogoSvg from "../assets/svgs/logoWhite.svg";
import LocationIcon from "../assets/svgs/location.svg";
import EmailIcon from "../assets/svgs/email.svg";
import PhoneIcon from "../assets/svgs/phone.svg";
import Icon4 from "../assets/svgs/4.svg";
import Icon2 from "../assets/svgs/2.svg";
import Icon1 from "../assets/svgs/1.svg";
import Icon3 from "../assets/svgs/3.svg";
import ArrowIcon from "../assets/svgs/arrowRight.svg";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const navigate = useNavigate();
  const shortcuts = [
    {
      title: "FBA Automation",
      iconImage: ArrowIcon,
      linkTo: "/amazon-fba/",
    },
    {
      title: "Tiktok Automation",
      iconImage: ArrowIcon,
      linkTo: "/tiktok-automation/",
    },
    {
      title: "Walmart Automation",
      iconImage: ArrowIcon,
      linkTo: "/walmart-automation/",
    },
    {
      title: "Shopify Automation",
      iconImage: ArrowIcon,
      linkTo: "/shopify-automation/",
    },
  ];
  const listMenu = [
    {
      title: "Contact Us",
      iconImage: ArrowIcon,
    },
    {
      title: "Terms & Conditions",
      iconImage: ArrowIcon,
      linkTo: "/terms-and-conditions/",
    },
    {
      title: "Privacy Policy",
      iconImage: ArrowIcon,
      linkTo: "/privay-policy/",
    },
    {
      title: "About Us",
      iconImage: ArrowIcon,
      linkTo: "/about/",
    },
  ];

  const navigateToPath = (path, title) => {
    if (title === "Contact Us") {
      const element = document.getElementById("contactSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      console.log(element);
    } else {
      navigate(path);
      window.scrollTo(0, 0);
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const navigateAddress = () => {
    const url =
      "https://www.google.com/maps?q=633+Duval+St,Tallahassee,+FL+32399,+USA";
    window.open(url, "_blank");
  };

  const navigateContact = () => {
    const url = "tel:+13862449569";
    window.location.href = url;
  };

  const vectorLinks = [
    {
      iconSrc: Icon4,
      linkTo: "https://www.trustpilot.com/review/fbaxperts.com",
    },
    {
      iconSrc: Icon2,
      linkTo: "https://www.reviews.io/company-reviews/store/fbaxperts.com",
    },
    {
      iconSrc: Icon1,
      linkTo: "https://www.sitejabber.com/reviews/fbaxperts.com",
    },
    {
      iconSrc: Icon3,
      linkTo: "https://www.bark.com/en/us/company/fba-xperts/RBlwy/",
    },
  ];

  const info = [
    {
      icon: LocationIcon,
      text: "55 Powel Ave Bethpage, NY 11714",
      link: "https://www.google.com/maps/place/55+Powell+Ave,+Bethpage,+NY+11714,+USA/@40.7432624,-73.479143,17z/data=!3m1!4b1!4m6!3m5!1s0x89c28022d17f409f:0x30a3bf711902e088!8m2!3d40.7432584!4d-73.4765681!16s%2Fg%2F11c4w8dxw_?entry=ttu",
    },
    {
      icon: LocationIcon,
      text: "633 Duval St, Tallahassee, FL 32399, USA",
      link: "https://www.google.com/maps?q=633+Duval+St,Tallahassee,+FL+32399,+USA",
    },
    {
      icon: EmailIcon,
      text: "query@fbaxperts.com",
      link: "mailto:query@fbaxperts.com",
    },
    {
      icon: PhoneIcon,
      text: "+13862449569",
      link: "tel:+13862449569",
    },
  ];

  return (
    <div className="footer-main global-background">
      <div className="container pt-5 pb-2">
        <div className="row pt-3 pb-4">
          <div className="col-lg-7 col-md-5">
            <div className="cursor-pointer">
              <img
                src={AppLogoSvg}
                alt="appLogo"
                onClick={() => handleNavigate("/")}
              />
            </div>
            <h1
              className="footer-heading text-theme-white font-face-Bold pt-3"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              Unleash Your Business{" "}
              <span className="d-lg-block">Potential with FBAxperts</span>
            </h1>
            <p
              className="text-theme-light-white font-face-Poppins-Medium"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              Get Ahead, Stay Ahead! Discover tailor-made solutions and
              <span className="d-lg-block">
                expert insights designed to supercharge your business growth.
              </span>
              Join the FBAXperts community and transform your business today.
            </p>
          </div>

          <div className="col-lg-3 col-md-4">
            <h3 className="font-face-Bold text-theme-white mt-3 mt-lg-0 mt-md-0">
              Quick Links
            </h3>
            <div className="mt-3">
              {shortcuts?.map((x, index) => {
                return (
                  <div
                    onClick={() => navigateToPath(x.linkTo)}
                    key={index}
                    className="d-flex align-items-start cursor-pointer py-2"
                  >
                    <div className="list-item">
                      <img src={x.iconImage} alt="rightArrow" />
                    </div>
                    <small className="text-theme-light-white font-face-Poppins-Medium ms-3">
                      {x.title}
                    </small>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-2 col-md-3 mt-3 mt-lg-0 mt-md-0">
            <h3 className="font-face-Bold text-theme-white">Support</h3>
            <div className="mt-3 ">
              {listMenu?.map((x, index) => {
                return (
                  <div
                    onClick={() => navigateToPath(x.linkTo, x.title)}
                    key={index}
                    className="d-flex align-items-start cursor-pointer py-2"
                  >
                    <div className="list-item">
                      <img src={x.iconImage} alt="rightArrow" />
                    </div>
                    <small className="text-theme-light-white font-face-Poppins-Medium ms-3">
                      {x.title}
                    </small>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            {info?.map((a, index) => {
              const isLast = index === info.length - 1;
              return (
                <a
                  key={index}
                  href={a.link}
                  className={`d-flex align-items-center ${
                    !isLast ? "mb-3" : ""
                  }`}
                  target={
                    a.link.startsWith("mailto:") || a.link.startsWith("tel:")
                      ? "_self"
                      : "_blank"
                  }
                >
                  <img src={a.icon} alt="infoIcon" />
                  <span className="font-face-Poppins-Medium text-theme-light-white ms-3">
                    {a.text}
                  </span>
                </a>
              );
            })}
          </div>
          <div className="col-md-6 pt-4">
            <div className="follow-box d-md-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center pb-3 pb-lg-0 pb-md-0">
                <h5 className="text-theme-light-white font-face-Poppins-Medium m-0">
                  Follow Us:
                </h5>

                <div className="d-flex align-self-center ms-3">
                  <div className="ms-2">
                    <a
                      href="https://www.linkedin.com/company/fbaxperts/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={linkdinIcon} alt="linkdinIcon" />
                    </a>
                  </div>
                  <div className="ms-2">
                    <a
                      href="https://www.instagram.com/fbaxperts?igsh=djF2ZTJqd3R6Ymh5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={instagramIcon} alt="instagramIcon" />
                    </a>
                  </div>

                  <div className="ms-2">
                    <a
                      href="https://www.facebook.com/profile.php?id=61559406977208"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={FaceBookIcon} alt="FaceBookIcon" />
                    </a>
                  </div>

                  <div className="ms-2">
                    <a
                      href="https://www.youtube.com/channel/UC3q1M4EL3TeSFPX1zN3vLeQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={youtubeIcon} alt="youtubeIcon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="vector-box d-flex align-items-center justify-content-end pt-3 ">
              {vectorLinks?.map((x, index) => {
                return (
                  <div key={index}>
                    <a href={x.linkTo} target="_blank">
                      <img
                        className="img-fluid mx-1"
                        src={x.iconSrc}
                        alt="truespolit"
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <hr className="text-theme-white" />
        <p className="font-face-Poppins-Medium text-theme-light-white text-center m-0">
          © 2024 All Rights Reserved
        </p>
        <p className="font-face-Poppins-Medium text-theme-light-white text-center m-0 pt-2">
          All logos and trademarks featured on our website belong to their
          respective owners. We have no affiliation, endorsement, or official
          connection with these companies or their trademarks. The inclusion of
          these logos and trademarks is solely for identification purposes. The
          information provided on our website is for general informational
          purposes only and should not be taken as professional advice. We do
          not guarantee the accuracy or completeness of the information
          presented. We are not liable for any errors or omissions, nor for any
          outcomes from using this information. Any reliance you place on such
          information is at your own risk.
        </p>
      </div>
    </div>
  );
};

export default Footer;
