import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import TawkTo from "tawkto-react";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import AmazonFBAPage from "./pages/AmazonFBAPage";
import NavigationBar from "../src/layout/NavigationBar";
import Footer from "../src/layout/Footer";
import FbExpertSubscribeForm from "./components/FbExpertSubscribeForm";
import TiktokAutomationPage from "./pages/TiktokAutomationPage";
import ShopifyAutomationPage from "./pages/ShopifyAutomationPage";
import WalmartAutomationPage from "./pages/WalmartAutomationPage";
import TermAndConditionPage from "./pages/TermAndConditionPage";
import PrivayPolicy from "./pages/PrivayPolicy";
import ThankYouPage from "./pages/ThankYouPage";
import whatsappimg from "../src/assets/images/whatsapp.png";
import phoneimg from "../src/assets/images/receiverIcon.png";
import AnimatedCursor from "react-animated-cursor";
import { useEffect, useState } from "react";
import PopupContactForm from "./components/PopupContactForm";

function App() {
  const [showForm, setShowForm] = useState(false);
  const location = useLocation();

  const handleButton = () => {
    const url = "https://wa.me/13862401886";
    window.open(url);
  };
  const navigateContact = () => {
    const url = "tel:+13862449569";
    window.location.href = url;
  };

  let propertyId = "665fd7689a809f19fb392087";
  let tawkId = "1hvj62tk7";

  useEffect(() => {
    if (
      location.pathname !== "/thankyou" &&
      location.pathname !== "/thankyou/"
    ) {
      var tawk = new TawkTo("665fd7689a809f19fb392087", "1hvj62tk7");

      tawk.onStatusChange((status) => {
        // handle status change if needed
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowForm(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* {location.pathname === "/thankyou/" ||
      location.pathname === "/thankyou" ? null : (
        <div className="receiver-icon">
          <img src={phoneimg} onClick={navigateContact} />
        </div>
      )} */}

      {location.pathname === "/thankyou/" ||
      location.pathname === "/thankyou" ? null : (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            zIndex: "9999",
            height: "50px",
            width: "50px",
          }}
        >
          <img
            src={whatsappimg}
            style={{
              height: "55px",
              width: "55px",
              borderRadius: "50px",
              cursor: "pointer",
            }}
            onClick={handleButton}
          />
        </div>
      )}

      {/* <AnimatedCursor
        innerSize={16}
        outerSize={16}
        color="254, 233, 166"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          "button",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="button"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      /> */}
      {showForm ? <PopupContactForm setShowForm={setShowForm} /> : null}
      <NavigationBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about/" element={<AboutPage />} />
        <Route path="/amazon-fba/" element={<AmazonFBAPage />} />
        <Route path="/tiktok-automation/" element={<TiktokAutomationPage />} />
        <Route
          path="/shopify-automation/"
          element={<ShopifyAutomationPage />}
        />
        <Route
          path="/walmart-automation/"
          element={<WalmartAutomationPage />}
        />
        <Route
          path="/terms-and-conditions/"
          element={<TermAndConditionPage />}
        />
        <Route path="/privay-policy/" element={<PrivayPolicy />} />
        <Route path="/thankyou/" element={<ThankYouPage />} />
      </Routes>
      {location.pathname === "/thankyou/" ||
      location.pathname === "/thankyou" ? null : (
        <FbExpertSubscribeForm />
      )}

      <Footer />
    </>
  );
}

export default App;
