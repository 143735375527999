import React, { useEffect } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import coreImage from "../assets/images/corevaluesImage.png";
import "./CoreValuesSlider.css";
import Aos from "aos";
import "aos/dist/aos.css";

const CoreValuesSlider = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const valuesImages = [
    {
      imageSource: require("../assets/images/corevaluesImage.png"),
    },
    {
      imageSource: require("../assets/images/corevaluesImage.png"),
    },
    {
      imageSource: require("../assets/images/corevaluesImage.png"),
    },
  ];
  const valuesData = [
    {
      icon: require("../assets/images/Cursor.png"),
      title: "Collaborative Excellence",
      description:
        "We thrive on teamwork. Together, we achieve exceptional results by leveraging our combined skills and expertise.",
    },
    {
      title: "Work-Life Harmony",
      description:
        "At FBAXperts, we value a balanced life. Our culture supports enjoying personal life while excelling professionally. ",
    },
    {
      title: "Inspiring Work Environment",
      description:
        "Our company fosters respect, innovation, and support. At FBAXperts, we create an environment that inspires growth and creativity.",
    },
  ];

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   dots: true,
  //   autoplay: true,
  //   arrows: false,
  //   // autoplaySpeed: 2000,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
  //         dots: true,
  //         arrows: false,
  //       },
  //     },
  //     {
  //       breakpoint: 912,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 2,
  //         dots: true,
  //         arrows: false,
  //       },
  //     },
  //     {
  //       breakpoint: 540,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         dots: true,
  //         arrows: false,
  //       },
  //     },
  //   ],
  // };
  return (
    <div className="core-values global-background">
      <div className="container py-5">
        <div className="row">
          {/* <div className="col-md-6 d-flex flex-column justify-content-center">
            <Slider {...settings}>
              {valuesImages?.map((x, index) => {
                return (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    key={index}
                  >
                    <img
                      src={x.imageSource}
                      alt="values-image"
                      className="img-fluid"
                    />
                  </div>
                );
              })}
            </Slider>
          </div> */}

          <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
            <img src={coreImage} alt="values-image" className="img-fluid" />
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-center px-lg-5 p-md-2">
            <h1
              className="font-face-Bold text-theme-white core-heading"
              data-aos="zoom-in"
              data-aos-duration="800"
            >
              Our Core Values
            </h1>
            {valuesData?.map((x, index) => {
              return (
                <div className="mt-3 mt-lg-4" key={index}>
                  {x.icon ? (
                    <div className="d-flex align-items-center">
                      <img src={x.icon} alt="pinkIcon" />
                      <h4 className="text-theme-second font-face-Manrop-Extra-Bol ms-3">
                        {x.title}
                      </h4>
                    </div>
                  ) : (
                    <h4 className="text-theme-white font-face-Manrop-Extra-Bol">
                      {x.title}
                    </h4>
                  )}

                  <div className="py-2">
                    <p className="text-theme-light-white m-0 font-face-Poppins-Regular">
                      {x.description}
                    </p>
                  </div>
                  <hr className="text-theme-light-white w-75" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValuesSlider;
