import React, { useEffect } from "react";
import "./AboutBanner.css";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutBanner = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  return (
    <div className="bg-black">
      <div
        style={{
          backgroundImage: `url(${props?.bannerImage})`,
        }}
        className="about-banner"
      >
        <div className="container py-5">
          <div className="row mx-auto">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h1
                className="about-heading font-face-Bold text-theme-white"
                data-aos="zoom-in"
                data-aos-duration="800"
              >
                {props?.bannerPrimaryHeading}
                <span className="d-block">{props?.bannerSecondaryHeading}</span>
                {props?.bannerTertiaryHeading ? (
                  <span>{props.bannerTertiaryHeading}</span>
                ) : null}
              </h1>
            </div>
            <div className="about-image col-md-6 d-flex flex-column justify-content-center align-items-end">
              <img
                src={props?.rightImage}
                alt="right-image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
