import React, { useEffect, useState } from "react";
import "./ResultDrivenSection.css";
import BlackIcon from "../assets/svgs/plusIcon.svg";
import Icon from "../assets/svgs/crossIcon.svg";
import mailIcon from "../assets/images/mailIcon.png";
import resultDrivenImage from "../assets/images/resultDrivenImage.png";

import Aos from "aos";
import "aos/dist/aos.css";

const ResultDrivenSection = (props) => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });

  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const FAQDATA = [
    {
      heading:
        "Do I really need Amazon Marketing Service Professionals for my business?",

      description:
        "An Amazon marketing service specialist will assist you to promote your product, effectively manage your online store, and bring more visitors to your product website, no matter whether you are a small seller or an established business. Consequently, you will be able to advertise your products effectively and adhere to all Amazon guidelines.",
    },
    {
      heading: "How do I know I have an effective Amazon strategy?",

      description:
        "You have an effective Amazon strategy in mind when your product is always ahead in the search list and thus continues to be more visible to consumers. Furthermore, your ad campaigns should be highly effective in terms of ROI, and, there should be a constant increase in the customer reviews and ratings. Periodically reviewing important activity measurements and adjusting the technique based on the numbers collected is essential for strategy optimization.",
    },
    {
      heading:
        "I have a successful Amazon store. Do I need to hire Amazon Experts for my online business?",

      description:
        "Hiring Amazon marketing experts can be advantageous for your company even when you already have a successful Amazon store. Advertisers can enhance your listings, advertise properly when it comes to campaigns, and offer you information on recent market and ad strategies. This is because they have specialized knowledge and will be able to attract more people into your store thus enhance sales revenue and growth.",
    },
    {
      heading:
        "How does FBAXperts ensure the growth of my E-commerce business?",

      description:
        "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
    },
    {
      heading:
        "Can FBAXperts handle multiple E-commerce platforms simultaneously?",

      description: `Yes, FBAExperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.`,
    },
  ];
  return (
    <div className="result-driven global-background">
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6 px-lg-5 d-flex flex-column justify-content-center">
            <h1
              className="driven-heading font-face-Bold text-theme-white text-center"
              data-aos="zoom-in"
              data-aos-duration="900"
            >
              We are ready to solve{" "}
              <span className="d-md-block">your problems</span>
            </h1>
            <div className="d-flex align-items-center justify-content-center">
              <img src={mailIcon} alt="mailIcon" />
              <a href="mailto:query@fbaxperts.com" className="email-link-sec">
                <p className="text-theme-light-white my-0 ms-3 font-face-Poppins-Medium text-center">
                  query@fbaxperts.com
                </p>
              </a>
            </div>

            <div className="mt-5">
              <img
                src={resultDrivenImage}
                alt="resultDrivenImage"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-6 px-lg-5 d-flex flex-column justify-content-center">
            <div className="FAQ-main">
              <div className="accordian">
                {props?.FAQDATA?.map((item, i) => (
                  <div className="item" key={i}>
                    <div className="title" onClick={() => toggle(i)}>
                      <div className="title-inner container">
                        {selected === i ? (
                          <h6 className="text-theme-white py-3 font-face-Manrope-Bold">
                            {item.heading}
                          </h6>
                        ) : (
                          <h6 className="py-3 text-theme-white font-face-Manrope-Bold">
                            {item.heading}
                          </h6>
                        )}
                        {selected === i ? (
                          <img src={Icon} alt="icon" />
                        ) : (
                          <img src={BlackIcon} alt="icon1" />
                        )}
                      </div>

                      <div
                        className={selected == i ? "content show" : "content"}
                      >
                        <div className="container">
                          <div className="row d-md-flex align-items-center">
                            <div className="col-md-12">
                              <p className="text-theme-light-white font-face-Poppins-Medium description-ethicalessence">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultDrivenSection;
