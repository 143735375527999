import React, { useEffect } from "react";
import AboutBanner from "../components/AboutBanner";
import bannerImage from "../assets/images/termConditionBanner.png";
import rightImage from "../assets/images/termandRightImage.png";
import ResultDrivenSection from "../components/ResultDrivenSection";
import Aos from "aos";
import "aos/dist/aos.css";
import DynamicSeo from "../utils/DynamicSeo";

const TermAndConditionPage = () => {
  useEffect(() => {
    Aos.init({ duration: 700 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "Our Promise: Superior";
  const bannerSecondaryHeading = "Terms and Conditions";

  const content = [
    {
      heading: "Personal Information",
      paragraph:
        "We collect and use personal information to improve our services. Your data is handled with the utmost care and confidentiality, ensuring it is only used for the essential purposes. We do not share your personal information with third parties without your explicit consent, except as required by law.",
    },
    {
      heading: "Security",
      paragraph:
        "The security of your data is paramount to us. We employ advanced security protocols and encryption technologies to safeguard your information from unauthorized access and breaches. Regular security audits and updates are conducted to ensure our systems remain secure against potential threats.",
    },
    {
      heading: "Cookies",
      paragraph:
        "Our website uses cookies to enhance your browsing experience. We understand your preferences and provide personalized content. You have the option to manage or disable cookies through your browser settings, but please note that doing so may affect the functionality of our services.",
    },
    {
      heading: "Refund Policy",
      paragraph:
        "We strive to ensure your happiness with our services. If you are not completely satisfied, we offer a clear and fair refund policy. Refund requests must be submitted within a specified period, and our team will review and process your request promptly.",
    },
    {
      heading: "How to Claim Your Refund",
      paragraph:
        "To claim your refund, please reach out to our customer support team with your order details and a brief explanation of the reason for your request. Our dedicated support staff is available to assist you through this process, ensuring that your concerns are addressed promptly and efficiently.",
      paragraph2:
        "You can contact us via email, phone, or through our website’s contact form. Make sure to provide all necessary information, including your order number, to expedite the process. Once we receive your refund request, our team will review it to ensure it meets our refund policy criteria.",
      paragraph3:
        "We will keep you updated on the status of your request and may ask for additional information if needed. If your refund is approved, it will be processed and the amount will be credited back to your original method of payment within a specified timeframe. ",
      paragraph4:
        "We strive to make the refund process as smooth and hassle-free as possible, reflecting our commitment to your satisfaction and trust in FBAXperts.",
    },
  ];

  const FAQDATA = [
    {
      heading: "What services does FBAXperts offer for E-commerce businesses?",

      description:
        "At FBAXperts, we provide specialized services for Amazon, Shopify, Walmart, and TikTok. Our services include account setup and management, inventory optimization, product listing and SEO, advertising and marketing strategies, and automation solutions to streamline your operations and drive growth.",
    },
    {
      heading: "How do I get started with FBAXperts?",
      description:
        "Getting started with FBAXperts is simple. Contact us through our website or call our customer service team. We will discuss your business needs, develop a tailored strategy, and guide you through the implementation process to ensure your success.",
    },
    {
      heading:
        "How does FBAXperts ensure the growth of my E-commerce business?",
      description:
        "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
    },
    {
      heading:
        "Can FBAXperts handle multiple E-commerce platforms simultaneously?",
      description:
        "Yes, FBAXperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.",
    },
    {
      heading:
        "How does FBAXperts stay updated with the latest E-commerce trends?",

      description: `Our team at FBAXperts continuously monitors the latest trends, updates, and best practices in the E-commerce industry. We regularly attend industry conferences, engage in professional development, and leverage advanced tools and technologies to ensure our clients benefit from the most current and effective strategies.`,
    },
  ];

  return (
    <>
      <div className="terms-condition global-background">
        <DynamicSeo
          title="Terms of Service | FBAXperts"
          description="These terms outline your rights and responsibilities when engaging with our platform, ensuring a transparent and secure partnership between you and FBAXperts."
        />
        <AboutBanner
          bannerImage={bannerImage}
          bannerPrimaryHeading={bannerPrimaryHeading}
          bannerSecondaryHeading={bannerSecondaryHeading}
          rightImage={rightImage}
        />

        <div className="container py-5">
          <div className="text-center">
            <h1
              data-aos="zoom-in"
              data-aos-duration="700"
              className="text-theme-white font-face-Bold text-center"
            >
              Our Commitment to{" "}
              <span className="text-theme-second">
                Transparency and Security
              </span>
            </h1>
            <p
              className="text-theme-white font-face-Manrop-Medium pt-2"
              data-aos="zoom-in"
              data-aos-duration="600"
            >
              At FBAXperts, we prioritize your trust and satisfaction. We
              believe in clear and open communication, ensuring that you are
              fully informed about how we handle your data and secure your
              transactions. We strive to uphold the highest standards in every
              aspect of our operations.
            </p>
            <p
              className="text-theme-white font-face-Manrop-Medium"
              data-aos="zoom-in"
              data-aos-duration="600"
            >
              Understanding the importance of your privacy, we have implemented
              robust measures to protect your information. This document
              outlines our terms and conditions, providing detailed insights
              into our practices regarding personal information, security,
              cookies, and our refund policy.
            </p>
          </div>

          {content?.map((x, index) => {
            return (
              <div key={index} className="pt-5">
                <h3
                  className="text-theme-second font-face-Poppins-Bold"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                >
                  {x.heading}
                </h3>
                <p
                  className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                  data-aos="zoom-in"
                  data-aos-duration="600"
                >
                  {x.paragraph}
                </p>
                {x.paragraph2 ? (
                  <p
                    className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  >
                    {x.paragraph2}
                  </p>
                ) : null}
                {x.paragraph3 ? (
                  <p
                    className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  >
                    {x.paragraph3}
                  </p>
                ) : null}
                {x.paragraph4 ? (
                  <p
                    className="text-theme-white font-face-Poppins-Regular m-0 pt-4"
                    data-aos="zoom-in"
                    data-aos-duration="600"
                  >
                    {x.paragraph4}
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>

        <ResultDrivenSection FAQDATA={FAQDATA} />
      </div>
    </>
  );
};

export default TermAndConditionPage;
