import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const CustomServices = (props) => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const data = props?.customServiceData;

  return (
    <div className="custom-services global-background">
      <div className="container py-3 py-lg-5 py-md-5">
        {data?.map((service, index) => (
          <div className="custom-inner row mb-5" key={index}>
            {index % 2 === 0 ? (
              <>
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <h2
                    className="text-white font-face-Bold mt-3 mt-lg-0 mt-md-0"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <span className="text-theme-second">
                      {service.headingOne}
                    </span>{" "}
                    <span className="d-md-block">{service.headingSecond}</span>{" "}
                    {service.headingThird ? service.headingThird : null}
                  </h2>
                  <div className="pt-2">
                    <p
                      className="responsive-width font-face-Poppins-Regular text-theme-light-white"
                      data-aos="zoom-in"
                      data-aos-duration="1200"
                    >
                      {service.descriptionOne}
                    </p>
                    <p
                      className="responsive-width font-face-Poppins-Regular text-theme-light-white"
                      data-aos="zoom-in"
                      data-aos-duration="1300"
                    >
                      {service.descriptionTwo}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-end">
                  <img
                    src={service.serviceImage}
                    alt="serviceImage"
                    className="img-fluid"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-start">
                  <img
                    src={service.serviceImage}
                    alt="serviceImage"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-6 d-flex flex-column justify-content-center">
                  <h2
                    className="text-white font-face-Poppins-Bold mt-3 mt-lg-0 mt-md-0"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <span className="text-theme-second">
                      {service.headingOne}
                    </span>{" "}
                    <span className="d-md-block">{service.headingSecond}</span>{" "}
                    {service.headingThird ? service.headingThird : null}
                  </h2>
                  <div className="pt-2">
                    <p
                      className="responsive-width font-face-Poppins-Regular text-theme-light-white"
                      data-aos="zoom-in"
                      data-aos-duration="1200"
                    >
                      {service.descriptionOne}
                    </p>
                    <p
                      className="responsive-width font-face-Poppins-Regular text-theme-light-white"
                      data-aos="zoom-in"
                      data-aos-duration="1300"
                    >
                      {service.descriptionTwo}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomServices;
