import React, { useState } from "react";
import "./PopupContactForm.css";
import closeIcon from "../assets/images/close.png";
import { useNavigate } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";

const PopupContactForm = (props) => {
  const navigate = useNavigate();

  const handleClose = () => props.setShowForm(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [notes, setNotes] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleKeyDown = (e) => {
    // Allow only numeric input
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== " " &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("emailForPopupForm");
    const nameInput = document.getElementById("nameForPopupForm");

    const phoneInput = document.getElementById("phoneForPopupForm");
    const messageInput = document.getElementById("messageForPopupForm");

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === "" ||
      messageInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    const response = await fetch(
      "https://backend.fbaxperts.com/api/email/V1/contactInfoMail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: phoneNumber,
          userMessage: notes,
        }),
      }
    );
    const responseData = await response.json();

    document.getElementById("myFormForPopupForm").reset();

    navigate("/thankyou/");
    props.setShowForm(false);
  };

  return (
    <div className="popup-container">
      <div className="close-popup text-end me-3" onClick={handleClose}>
        <img src={closeIcon} alt="close-cion" />
      </div>
      <div className="popup-content">
        <div className="text-center">
          <h4 className="font-face-Poppins-Semi-Bold">Send us a message</h4>
          <p className="popup-para font-face-Poppins-Regular">
            Feel free to get in touch, we're here to help with any questions or
            concerns you may have.
          </p>
        </div>
        <form onSubmit={handleSubmit} id="myFormForPopupForm">
          <div className="mt-4">
            <label htmlFor="fullName">
              <small>Full Name</small>
            </label>
            <div className="mt-2">
              <input
                id="nameForPopupForm"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4">
            <label>
              <small>Email</small>
            </label>
            <div className="mt-2">
              <input
                id="emailForPopupForm"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-4">
            <label>
              <small>Phone Number</small>
            </label>
            <div className="mt-2">
              <input
                id="phoneForPopupForm"
                onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="fullName">
              <small>Message</small>
            </label>
            <div className="mt-2">
              <textarea
                id="messageForPopupForm"
                rows={2}
                className="w-100 font-face-Poppins-Medium"
                onChange={(e) => setNotes(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <button className="popup-btn font-face-Poppins-Medium">
              Submit Request
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupContactForm;
