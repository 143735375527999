import React, { useEffect } from "react";
import ResultDrivenSection from "../components/ResultDrivenSection";
import homeIcon from "../assets/images/homeIcon.png";

import "./ThankYouPage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import StoryCard from "../components/StoryCard";
import { useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 800 });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const FAQDATA = [
    {
      heading: "What services does FBAXperts offer for E-commerce businesses?",

      description:
        "At FBAXperts, we provide specialized services for Amazon, Shopify, Walmart, and TikTok. Our services include account setup and management, inventory optimization, product listing and SEO, advertising and marketing strategies, and automation solutions to streamline your operations and drive growth.",
    },
    {
      heading: "How do I get started with FBAXperts?",
      description:
        "Getting started with FBAXperts is simple. Contact us through our website or call our customer service team. We will discuss your business needs, develop a tailored strategy, and guide you through the implementation process to ensure your success.",
    },
    {
      heading:
        "How does FBAXperts ensure the growth of my E-commerce business?",
      description:
        "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
    },
    {
      heading:
        "Can FBAXperts handle multiple E-commerce platforms simultaneously?",
      description:
        "Yes, FBAXperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.",
    },
    {
      heading:
        "How does FBAXperts stay updated with the latest E-commerce trends?",

      description: `Our team at FBAXperts continuously monitors the latest trends, updates, and best practices in the E-commerce industry. We regularly attend industry conferences, engage in professional development, and leverage advanced tools and technologies to ensure our clients benefit from the most current and effective strategies.`,
    },
  ];

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <div className="global-background">
      <div className="thankyou-banner">
        <div className="container py-5 text-white">
          <div className="head-box text-center">
            <h1
              className="m-0 text-uppercase font-face-Bold text-theme"
              data-aos="zoom-in-up"
              data-aos-duration="800"
            >
              Thank You For Contacting{" "}
              <span className="text-theme-white d-md-block">
                Us and Reaching Out
              </span>
            </h1>

            <p
              className="pt-3 font-face-Poppins-Medium"
              data-aos="zoom-in-up"
              data-aos-duration="1000"
            >
              We appreciate you taking the time to complete our form. Your
              information has been successfully received and is currently being
              reviewed by our team. We will reach out to you promptly to follow
              up on your submission.
              <div className="py-4">
                <button
                  className="back-to-home"
                  onClick={() => handleNavigate("/")}
                >
                  Back To Home
                  <img src={homeIcon} alt="homeIcon" />
                </button>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className="pt-3 pt-lg-0 pt-md-0">
        <StoryCard />
      </div>

      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default ThankYouPage;
