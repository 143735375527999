import "./VideoTestimonial.css";
import video1 from "../assets/videos/Alex HD.mp4";
import video2 from "../assets/videos/Emily HD.mp4";
import video3 from "../assets/videos/Jesse HD.mp4";
import video4 from "../assets/videos/Michale HD.mp4";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import AlexPoster from "../assets/videos/Alex 1.jpg";
import EmilyPoster from "../assets/videos/Emily 1.jpg";
import JessePoster from "../assets/videos/Jesse 1.jpg";
import MichalePoster from "../assets/videos/Michale.jpg";
import playImg from "../assets/svgs/play.svg";
import { useState } from "react";

const videos = [
  { url: video1, thumbnail: AlexPoster },
  { url: video2, thumbnail: EmilyPoster },
  { url: video3, thumbnail: JessePoster },
  { url: video4, thumbnail: MichalePoster },
];

const VideoTestimonial = () => {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);

  function openLightBoxContainer(i) {
    setSlideIndex(i);
    setOpenLightBox(true);
  }
  return (
    <div className="video-testimonial row g-0 justify-content-center w-100 p-4 global-background">
      {videos?.map((video, i) => (
        <div className="col-12 col-md-6  position-relative">
          <img
            src={video.thumbnail}
            alt="Video Thumbnail"
            className="thumbnail w-100 p-2 cursor-pointer"
            onClick={() => openLightBoxContainer(i)}
          />
          <img
            src={playImg}
            alt="Play"
            className="play-img"
            onClick={() => openLightBoxContainer(i)}
          />
        </div>
      ))}
      <Lightbox
        open={openLightBox}
        close={() => setOpenLightBox(false)}
        index={slideIndex}
        plugins={[Video]}
        slides={videos?.map((video) => {
          return {
            type: "video",
            width: 1280,
            height: 720,
            poster: video.thumbnail,
            sources: [
              {
                src: video.url,
                type: "video/mp4",
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default VideoTestimonial;
