import React, { useEffect } from "react";
import "./HeroImage.css";
import toggleIcon from "../assets/images/heroImageIcon.png";
// import homeIcon from "../assets/images/homeIcon.png";
import homeIcon from "../assets/images/telephone.png";
// import rightImage from "../assets/images/heroRightImage.png";
import rightImage from "../assets/images/rightHero.png";
import tabletImage from "../assets/images/heroTabletImage.png";
import startImage from "../assets/images/startImage.png";
import mobileImage from "../assets/images/heroMobile.png";
import AnimatedCursor from "react-animated-cursor";

import Aos from "aos";
import "aos/dist/aos.css";
const HeroImage = () => {
  useEffect(() => {
    Aos.init({ duration: 800 });
  });
  const navigateToContactSection = () => {
    const element = document.getElementById("contactSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const openTawkToChat = () => {
    // Open TawkTo chat
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  };
  return (
    <>
      {/* <AnimatedCursor
        innerSize={16}
        outerSize={16}
        color="254, 233, 166"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          "button",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="button"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      /> */}

      <section className="hero-main">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-8 d-flex flex-column justify-content-center">
              <div className="d-flex align-items-center pb-3">
                <img src={toggleIcon} alt="toggleIcon" />
                <p className="text-theme-white my-0 ms-3 pt-2 pt-lg-0 pt-md-0 font-face-Poppins-Medium ">
                  Boost your Digital Presence on Amazon, TikTok,
                  <span className="d-md-block">
                    Walmart & Shopify with FBAXperts
                  </span>
                </p>
              </div>
              <div>
                <h1
                  className="hero-heading font-face-Bold "
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  Drive Revenue &
                  <span className="d-lg-block"> Dominate the Digital</span>{" "}
                  World with Us
                </h1>

                <p
                  className="hero-paragraph text-theme-light-white font-face-Poppins-Regular"
                  data-aos="zoom-in"
                  data-aos-duration="1100"
                >
                  Ensure your products stand out and soar to the top with our
                  <span className="d-lg-block">
                    comprehensive E-commerce services. We specialize in driving
                  </span>
                  consistent sales growth, optimizing your store for maximum
                  <span className="d-lg-block">
                    visibility, and making sure your products never get lost in
                  </span>
                  <span className="d-lg-block">the crowd.</span>
                </p>
              </div>

              <div className="py-4 text-center text-lg-start text-md-start">
                <button className="global-dark-btn" onClick={openTawkToChat}>
                  Let's Chat
                </button>
                <button
                  className="global-dark-transparent"
                  onClick={navigateToContactSection}
                >
                  Contact Us
                  <img src={homeIcon} alt="homeIcon" />
                </button>
              </div>

              {/* <div className="d-flex">
                <img src={leftArrow} alt="leftArrow" />
                <img className="ms-3" src={rightArrow} alt="rightArrow" />
              </div> */}

              <div className="pt-3">
                <img src={startImage} alt="startImage" />
                <p className="hero-paragraph pt-2 text-theme-light-white font-face-Poppins-Regular">
                  FBAXperts has revolutionized our online strategy. Their
                  insights and solutions
                  <span className="d-lg-block">
                    are unparalleled, and our growth has been exponential.
                    Highly recommend!"
                  </span>
                </p>
                <p className="text-theme-white font-face-Poppins-Semi-Bold">
                  Emerald Stephen
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              <img
                src={rightImage}
                alt="rightImage"
                className="web-image img-fluid"
              />
              <img
                src={tabletImage}
                alt="rightImage"
                className="tablet-image img-fluid"
              />
              <img
                src={mobileImage}
                alt="mobileImage"
                className="mobile-image img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroImage;
