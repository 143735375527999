import React, { useEffect } from "react";
import bannerImage from "../assets/images/shopifyBanner.png";
import rightImage from "../assets/images/shopifyRightImage.png";
import AboutBanner from "../components/AboutBanner";
import CustomServices from "../components/CustomServices";
import FbExpertsPortfolio from "../components/FbExpertsPortfolio";
import ProductExcellence from "../components/ProductExcellence";
import ResultDrivenSection from "../components/ResultDrivenSection";
import DynamicSeo from "../utils/DynamicSeo";

const ShopifyAutomationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerPrimaryHeading = "We Always Give The Best";
  const bannerSecondaryHeading = "Shopify Automation";
  const bannerTertiaryHeading = "Service To You";

  const customServiceData = [
    {
      headingOne: "Achieve Unparalleled Success in the",
      headingSecond: "E-commerce World with Our",
      headingThird: "Shopify Automation Services",
      descriptionOne:
        "At FBAXperts, we specialize in empowering entrepreneurs and mid-sized businesses through our cutting-edge Shopify automation services. We identify profitable products, boost sales, and provide custom branding solutions, ensuring your Shopify store operates flawlessly and stands out in the competitive e-commerce world.",
      descriptionTwo:
        "Expand your product range without the headaches of inventory maintenance and shipping logistics. Our solutions allow you to innovate, explore new markets, and enhance the customer experience, all while maximizing your earnings.",
      serviceImage: require("../assets/images/shopifyService.png"),
    },
    {
      headingOne: "Boost Your Shopify Business",
      headingSecond: "with Our Cutting-Edge Services",
      descriptionOne:
        "Our Shopify automation services seamlessly integrates with your Shopify platform, thus, revolutionizing your operations. By automating repetitive tasks and optimizing workflows, we ensure your store operates smoothly. With us, you'll witness a surge in productivity and a significant reduction in operational costs.",
      descriptionTwo:
        "By leveraging our Shopify Automation Services, you'll not only save time but also unlock new opportunities for growth. With more efficient operations, you can scale your business faster and reach new heights in the e-commerce realm. Partner with FBAXperts today and see your Shopify store soar.",
      serviceImage: require("../assets/images/shopifyService2.png"),
    },
  ];

  const productExcellenceData = [
    {
      productTitleOne: "We Offer Exclusive Services",
      productTitleTwo: "for Your Digital Products",
      productDescription:
        "We offer premium quality Shopify automation services designed to improve your online store's efficiency and profitability, automate repetitive tasks, & streamline operations.",
      productIconOne: require("../assets/images/ProductExcellenceIcon1.png"),
      featureProducts: [
        {
          icon: require("../assets/images/ProductExcellenceIcon1.png"),
          featureHeading: "Inventory Management Automation",
          description:
            "Enhance inventory control with automated systems to maintain optimal stock levels and prevent stockouts. ",
        },
        {
          icon: require("../assets/images/ProductExcellenceIcon2.png"),
          featureHeading: "Order Processing Automation",
          description:
            "Improve efficiency & attain massive profitability by automating order fulfillment processes & make leaps in competitive world. ",
        },
      ],

      productImage: require("../assets/images/ProductExcellenceImage3.png"),
      productImageMobile: require("../assets/images/ProductExcellenceImage3Mobile.png"),
    },
  ];

  const portfolioImags = [
    {
      revenueImage: require("../assets/images/Shopify/1.png"),
    },
    {
      revenueImage: require("../assets/images/Shopify/2.png"),
    },
    {
      revenueImage: require("../assets/images/Shopify/3.png"),
    },
    {
      revenueImage: require("../assets/images/Shopify/4.png"),
    },
    {
      revenueImage: require("../assets/images/Shopify/5.png"),
    },
    {
      revenueImage: require("../assets/images/Shopify/6.png"),
    },
  ];

  const FAQDATA = [
    {
      heading: "How do I start dropshipping on Shopify?",

      description:
        "To start dropshipping on Shopify, first, set up your store. Then, research and select niche products from suppliers on platforms like Oberlo or Modalyst. Import these products into your store, set prices and descriptions, and market your offerings using social media and advertising. When orders are placed, suppliers ship products directly to your customers.",
    },
    {
      heading: "Is Shopify Dropshipping is worth investing in 2024 and beyond?",

      description:
        "While Shopify Dropshipping can still be profitable in 2024, its success depends on various factors. To succeed, thorough niche exploration, unique branding, effective marketing strategies, and excellent customer service are essential. For that, you can connect with us at FBAXperts & let us handle all your business needs.",
    },
    {
      heading: "What makes Shopify a popular choice for e-commerce businesses?",

      description:
        "Shopify's popularity in the dropshipping community is due to its user-friendly interface, customizable themes, and wide range of available apps. It simplifies the process of setting up a store, managing products, and handling orders. Shopify's convenience and feature-rich environment make it a preferred platform for many dropshipping entrepreneurs.",
    },
    {
      heading:
        "How can I find reliable suppliers for my Shopify Dropshipping store?",

      description:
        "To find reliable suppliers for your Shopify Dropshipping store, research reputable suppliers on platforms like Oberlo, Modalyst, AliExpress, or through direct contact with manufacturers. Look for suppliers with good reviews, a history of timely delivery, and quality products. Communicate with potential suppliers to establish a partnership that aligns with your business needs.",
    },
    {
      heading: "How FBAXperts are different from other Shopify experts?",

      description: `We understand that every business is unique. That's why we offer tailored solutions that fit your specific needs, ensuring you get the most out of your Shopify store.
      Our team consists of Shopify experts with years of experience. We stay updated with the latest trends and best practices to ensure your store is always ahead of the curve.
      Despite our high-quality services, we offer competitive pricing to make our services accessible to businesses of all sizes.
      `,
    },
  ];
  return (
    <div>
      <DynamicSeo
        title="Shopify Automation Services | FBAXperts"
        description="Searching for a reliable e-commerce business to help you elevate your Shopify store? You can reach to our exclusive business & get incredible Shopify Automation Services today."
      />
      <AboutBanner
        bannerImage={bannerImage}
        bannerPrimaryHeading={bannerPrimaryHeading}
        bannerSecondaryHeading={bannerSecondaryHeading}
        bannerTertiaryHeading={bannerTertiaryHeading}
        rightImage={rightImage}
      />
      <CustomServices customServiceData={customServiceData} />
      <FbExpertsPortfolio portfolioImags={portfolioImags} />
      <ProductExcellence productExcellenceData={productExcellenceData} />
      <ResultDrivenSection FAQDATA={FAQDATA} />
    </div>
  );
};

export default ShopifyAutomationPage;
