import React, { useEffect } from "react";
import "./StoryCard.css";

import Aos from "aos";
import "aos/dist/aos.css";

const StoryCard = () => {
  useEffect(() => {
    Aos.init({ duration: 900 });
  });
  const cards = [
    {
      cardImage: require("../assets/images/storyCard1.png"),
      cardDate: "January 10, 2024",
      cardTitle: "Plan business easy collaboration with FBA XPERTS",
      liinkImage: require("../assets/images/rightArrow.png"),
    },
    {
      cardImage: require("../assets/images/storyCard2.png"),
      cardDate: "March 20, 2024",
      cardTitle: "Wonderful !!! Cooperation together with the world’s best",
      liinkImage: require("../assets/images/rightArrow.png"),
    },
    {
      cardImage: require("../assets/images/storyCard3.png"),
      cardDate: "February 14, 2024",
      cardTitle:
        "Effortless Business Collaboration Made Simple with FBA XPERTS",
      liinkImage: require("../assets/images/rightArrow.png"),
    },
    {
      cardImage: require("../assets/images/storyCard4.png"),
      cardDate: "April 25, 2024",
      cardTitle: "Impressive! Collaborating with top-tier professionals",
      liinkImage: require("../assets/images/rightArrow.png"),
    },
  ];

  return (
    <div className="story-card global-background">
      <div className="container py-0 py-md-5 py-lg-5">
        <div className="text-center">
          <div className="global-light-btn mx-auto">
            Our Stories About Anything Business
          </div>

          <h1
            className="text-theme-white font-face-Bold pt-4"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            Many interesting stories{" "}
            <span className="d-md-block">that you can see with us</span>
          </h1>
        </div>

        <div className="row pt-5">
          {cards?.map((x, index) => {
            return (
              <div key={index} className="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <div className="story-main">
                  <img
                    className="img-fluid"
                    src={x.cardImage}
                    alt="Card Image"
                  />
                  <p className="text-theme-light-white font-face-Manrope-Extra-Bold pt-3 mb-0">
                    {x.cardDate}
                  </p>
                  <h4 className="text-theme-white lh-base font-face-Bold py-md-3 px-md-1">
                    {x.cardTitle}
                  </h4>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      //{" "}
    </div>
  );
};

export default StoryCard;
